<style lang="scss">.final-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden; }
  .final-wrapper.scroll {
    overflow-y: scroll;
    overflow-x: hidden; }
/*# sourceMappingURL=src/components/Final/Final.svelte.map */</style>

<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import Intro from './Intro.svelte';
  import About from './About.svelte';
  import Partners from './Partners.svelte';
  import Museum from './Museum.svelte';
  import Rights from './Rights.svelte';
  import Myths from './Myths.svelte';
  import { TweenMax } from 'gsap';

  let introRef;
  let partnersRef;
  let aboutRef;
  let museumRef;
  let routes;
  let wrapperRef;
  let rightsRef;
  let mythsRef;
  let activeRoute;

  const dispatch = createEventDispatcher();

  onMount(() => {
    TweenMax.set(wrapperRef, { zIndex: -1, opacity: 0 });
    routes = {
      about: aboutRef,
      partners: partnersRef,
      museum: museumRef,
      rights: rightsRef,
      myths: mythsRef
    };
  });

  export function enterAnimation() {
    introRef.assignSupportLgbt();
    TweenMax.set(wrapperRef, { zIndex: 999 });
    TweenMax.to(wrapperRef, 0.4, { opacity: 1 });
  }

  export function restartIntro() {
    introRef.enterAnimation();
    TweenMax.set(wrapperRef, { zIndex: 999 });
  }

  function back() {
    activeRoute = null;
    introRef.enterAnimation();
  }

  function backToThirdStep() {
    introRef.exitAnimation();
    TweenMax.set(wrapperRef, { zIndex: -1 });
  }

  function next(event) {
    activeRoute = event.detail;
    introRef.exitAnimation();
    routes[event.detail].enterAnimation();
  }

  export async function goToPage(page) {
    enterAnimation();
    activeRoute = page;
    await introRef.exitAnimation();
    routes[page].enterAnimation();
  }

  function goToStart() {
    // introRef.exitAnimation();
    console.log('GO TO START!');
    TweenMax.to(wrapperRef, 0.2, {
      opacity: 0,
      onComplete: () => {
        dispatch('goToStart');
      }
    });
  }
</script>

<div class="final-wrapper" class:scroll="{activeRoute === 1}" bind:this="{wrapperRef}">
  <Intro
    on:goToStart="{goToStart}"
    bind:this="{introRef}"
    on:back="{backToThirdStep}"
    on:next="{next}"
  />
  <About bind:this="{aboutRef}" on:back="{back}" />
  <Partners bind:this="{partnersRef}" on:back="{back}" />
  <!-- <Museum bind:this="{museumRef}" on:back="{back}" /> -->
  <Rights bind:this="{rightsRef}" on:back="{back}" />
  <Myths bind:this="{mythsRef}" on:back="{back}" />
</div>
