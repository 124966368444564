<style lang="scss">.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1; }
  .glide__arrow:focus {
    outline: none; }
  .glide__arrow:hover {
    border-color: white; }
  .glide__arrow--left {
    left: 2em; }
  .glide__arrow--right {
    right: 2em; }
  .glide__arrow--disabled {
    opacity: 0.33; }

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%); }

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em; }
  .glide__bullet:focus {
    outline: none; }
  .glide__bullet:hover, .glide__bullet:focus {
    border: 2px solid white;
    background-color: rgba(255, 255, 255, 0.5); }
  .glide__bullet--active {
    background-color: white; }

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; }

.glide {
  max-width: 75vw;
  width: 100%;
  margin: 0 auto; }
  .glide img {
    width: 100%;
    height: 100%; }
  .glide__slide {
    height: 280px; }
    .glide__slide img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

.glide__arrow {
  border: none;
  color: transparent;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  outline: none; }
  .glide__arrow--left {
    left: -3rem; }
  .glide__arrow--right {
    right: -3rem;
    transform: translate(0, -50%) rotate(180deg); }

.slider {
  margin-bottom: 24px; }
  .slider-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.04em;
    margin-bottom: 17px; }

.collection {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll; }
  .collection-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%; }
  .collection .padding-sm {
    padding: 0 20px; }
  .collection__title {
    font-family: Transgender Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(26, 26, 26, 0.8);
    width: 300px; }
  .collection__subtitle {
    font-family: Montserrat;
    margin-top: 36px;
    font-size: 1.125rem;
    line-height: 135.4%;
    letter-spacing: 0.02em;
    color: #1a1a1a; }
  .collection__description {
    margin-top: 18px;
    font-family: Montserrat;
    font-size: 0.875rem;
    line-height: 135.4%;
    letter-spacing: 0.02em;
    color: #1a1a1a; }
  .collection-people {
    color: #000000;
    font-size: 0.875rem;
    letter-spacing: 0.04em;
    font-family: Montserrat;
    width: 100%;
    margin-top: 27px;
    margin-bottom: 40px; }
    .collection-people__title {
      font-weight: bold;
      line-height: 1.125rem;
      margin-bottom: 14px; }
    .collection-people__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;
      line-height: 1.25rem; }
      .collection-people__list .item {
        display: flex;
        margin-left: 6px; }
      .collection-people__list a {
        text-decoration: none;
        color: #000; }
      .collection-people__list .icon-wrapper {
        width: 10px;
        height: 10px;
        margin-left: 3px; }
        .collection-people__list .icon-wrapper img {
          width: 100%;
          height: 100%; }

.popup-block {
  margin-top: 49px; }

.popup-title {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  letter-spacing: 0.05em;
  margin-bottom: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #1a1a1a; }

.popup-text {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 20px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0.05em;
  color: #1a1a1a; }
  .popup-text .number {
    grid-column: span 1;
    font-size: 2.125rem;
    line-height: 2.5625rem;
    color: #fff;
    -webkit-text-stroke: 1px black;
    margin-right: 20px;
    width: 30px; }
  .popup-text .text {
    grid-column: span 5;
    align-self: center;
    font-family: Montserrat; }

.footer {
  margin-top: auto;
  margin-bottom: 27px; }
  .footer-text {
    align-self: center;
    margin: auto auto 12px auto;
    font-family: Montserrat;
    letter-spacing: 0.04em;
    color: #000000;
    opacity: 0.8;
    max-width: 200px;
    font-size: 0.625rem;
    line-height: 0.75rem;
    opacity: 0.8;
    text-align: center; }
  .footer .el-wrapper {
    margin-bottom: 12px; }

.btn-wrapper {
  margin-top: 49px; }

.image-wrapper {
  position: relative;
  left: -2rem;
  margin: 37px 0;
  width: 470px;
  height: 360px; }
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    object-position: top center;
    opacity: 0; }

.bold {
  font-weight: 500;
  font-family: 'Montserrat-Medium'; }

.winners {
  margin-bottom: 16px; }

.item-winner {
  position: relative;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.04em;
  margin-bottom: 6px;
  margin-left: 20px;
  font-family: Montserrat;
  color: #000; }
  .item-winner a {
    color: #000;
    text-decoration: none; }
  .item-winner:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    background-image: url("/images/instagram.svg");
    background-size: contain;
    width: 14px;
    height: 14px; }

.success-title {
  font-size: 0.875rem;
  line-height: 1.4375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1a1a1a; }

.success-people {
  color: #000000;
  font-size: 0.75rem;
  letter-spacing: 0.04em;
  font-family: Montserrat;
  width: 100%;
  margin-top: 63px;
  margin-bottom: 30px; }
  .success-people__title {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 10px; }
  .success-people__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px; }
    .success-people__list .item {
      display: flex;
      margin-left: 6px;
      font-size: 0.875rem;
      font-family: Montserrat;
      line-height: 1.25rem;
      letter-spacing: 0.04em;
      color: #000000;
      opacity: 0.8; }
    .success-people__list a {
      text-decoration: none;
      color: #000; }
    .success-people__list .icon-wrapper {
      width: 10px;
      height: 10px;
      margin-left: 3px; }
      .success-people__list .icon-wrapper img {
        width: 100%;
        height: 100%; }

.toggle {
  margin-top: 7px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: Montserrat;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #1a1a1a;
  opacity: 0.3;
  cursor: pointer; }

.users-list-block {
  margin-bottom: 40px; }
/*# sourceMappingURL=src/components/Collection.svelte.map */</style>

<script>
  import { FirebaseApp, Collection } from 'sveltefire';
  import firebase from '../helpers/firebase';
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import Button from './Button.svelte';
  import Footer from './Footer.svelte';
  import MobileSharing from './MobileSharing.svelte';
  import { getGtag } from '../tracking';
  import Menu from './HeaderMenu.svelte';
  import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
  // import gaxios from 'gaxios'

  const dispatch = createEventDispatcher();

  let gtag;
  let stepText;
  let figureContainer;
  let mask;
  let figure;
  let timeline;
  let infinityTimeline;
  let buttons;
  let wrapperRef;
  let fetchDataBtn;
  let interval = null;
  let toggleButton;

  let randomTen = [];
  let finalWinners = [];
  let data = [];
  let photos = [];
  let contacts = {
    items: [],
    total: 0
  };

  export let successSubmit = false;
  let expanded = false;

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/waitlist');
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
  }
  function goToMain() {
    dispatch('goToMain');
  }
  function goToAbout() {
    dispatch('goToAbout');
  }
  function goToRights() {
    dispatch('goToRights');
  }
  function goToMiths() {
    dispatch('goToMiths');
  }
  function goToCollection() {
    dispatch('goToCollection');
  }

  function toggleBlock() {
    expanded = !expanded;
    expanded ? (toggleButton.innerHTML = 'Звернути') : (toggleButton.innerHTML = 'Читати повністю');
  }

  function wordDeclension(value, word) {
    if (value % 10 > 1 && value % 10 < 5) {
      return word[0];
    }
    if (value % 10 == 1) {
      return word[1];
    } else {
      return word[2];
    }
  }

  function getTenRandomUsers() {
    fetch(`https://${"api.riznirivni.com.ua"}/random-10`)
      .then(response => response.json())
      .then(item => (randomTen = item))
      .catch(() => []);
  }

  onMount(async () => {
    await Promise.all([
      fetch(`https://${"api.riznirivni.com.ua"}/week_winners`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/photos`)
        .then(response => response.json())
        .catch(() => []),

      fetch(`https://${"api.riznirivni.com.ua"}/forms`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/random-10`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/final-winners`)
        .then(response => response.json())
        .catch(() => [])
    ]).then(([res1, res2, res3, res4, res5]) => {
      data = res1;
      photos = res2;
      contacts = res3;
      randomTen = res4;
      finalWinners = res5;
    });
    let images = document.querySelectorAll('[data-index]');
    images[0].style.opacity = 1;

    function fadeIn(el) {
      gsap.fromTo(el, { opacity: 0 }, { opacity: 1 });
    }
    function fadeOut(el) {
      gsap.fromTo(el, { opacity: 1 }, { opacity: 0 });
    }

    let active = 0;
    let previous = 0;

    function animate() {
      interval = setInterval(() => {
        previous = active;
        active = active === images.length - 1 ? 0 : active + 1;
        fadeOut(images[previous]);
        fadeIn(images[active]);
      }, 4000);
    }

    animate();

    TweenMax.set(wrapperRef, { opacity: '1' });
  });
</script>

<div class="collection" bind:this="{wrapperRef}">
  <Menu
    on:goToMain="{goToMain}"
    on:goToAbout="{goToAbout}"
    on:goToRights="{goToRights}"
    on:goToMiths="{goToMiths}"
    on:goToCollection="{goToCollection}"
  />
  <div class="collection-wrapper">
    <div class="padding-sm">
      <div class="collection__title">ПРОДОВЖУЙ ХВИЛЮ ПІДТРИМКИ</div>
      <div class="collection__subtitle">
        Колекція Рівності в колаборації LITKOVSKAYA х BEVZA х KSENIASCHNAIDER подорожує від людини
        до людини. І всі, хто дружній до ЛГБТК+, може заявити про свою позицію.
      </div>
      <div class="collection__description">
        Залишаємо списки відкритими, щоб ви завжди знали, кому можна передати річ і поширити хвилю
        підтримки ❤️
      </div>
      <div class="success-people">
        <div class="success-people__title">У фінальному дропі речі отримали:</div>

        <div class="success-people__list">
          {#each finalWinners as contact, i}
            {#if i < 10 && expanded === false}
              <a class="item" href="{contact.link}" target="_blank">
                {contact.fullName}
                <div class="icon-wrapper">
                  <svg
                    width="10px"
                    height="10px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 55.99 76.6"
                    style="enable-background:new 0 0 55.99 76.6;"
                    xml:space="preserve"
                    fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                  >
                    <path
                      class="st0"
                      d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                      c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                      c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                      c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                      c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                      C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                    ></path>
                  </svg>
                </div>
              </a>
            {/if}
            {#if expanded === true}
              <a class="item" href="{contact.link}" target="_blank">
                {contact.fullName}
                <div class="icon-wrapper">
                  <svg
                    width="10px"
                    height="10px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 55.99 76.6"
                    style="enable-background:new 0 0 55.99 76.6;"
                    xml:space="preserve"
                    fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                  >
                    <path
                      class="st0"
                      d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                      c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                      c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                      c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                      c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                      C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                    ></path>
                  </svg>
                </div>
              </a>
            {/if}
          {/each}
        </div>
        <div class="toggle" on:click="{toggleBlock}" bind:this="{toggleButton}">
          Читати повністю
        </div>
      </div>
      <div class="">
        <div class="users-list-block">
          <div class="success-people__title">Люди, яким можна передати речі далі:</div>
          <div class="">
            <div class="success-people__list">
              {#each randomTen as contact, i}
                <a class="item" href="{contact.link}" target="_blank">
                  {contact.fullName}
                  <div class="icon-wrapper">
                    <svg
                      width="10px"
                      height="10px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 55.99 76.6"
                      style="enable-background:new 0 0 55.99 76.6;"
                      xml:space="preserve"
                      fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                    >
                      <path
                        class="st0"
                        d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                        c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                        c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                        c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                        c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                        C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                      ></path>
                    </svg>
                  </div>
                </a>
              {/each}
            </div>
          </div>
          <div class="toggle" on:click="{getTenRandomUsers}">Оновити</div>

        </div>
      </div>

      {#if photos.length}
        <div class="image-wrapper">
          <div class="image-wrapper__inner">
            {#each photos as photo, i}
              <img src="{photo}" alt="" data-index="{i}" />
            {/each}
          </div>
        </div>
      {/if}
      <div class="footer">
        <div class="footer-text">
          Стань частиною Різні.Рівні
          <br />
          Рух друзів ЛГБТК+ на підтримку рівності для всіх
        </div>
        <div class="el-wrapper">
          <MobileSharing />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</div>
