<style lang="scss">.slider {
  background-color: #ededed;
  /* height: 100%; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  user-select: none; }
  .slider__slide {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .slider__slide.active-step {
      z-index: 999; }
  .slider * {
    user-select: none; }
/*# sourceMappingURL=src/components/Slider.svelte.map */</style>

<script>
  import FirstStep from './FirstStep.svelte';
  import SecondStep from './SecondStep.svelte';
  import ThirdStep from './ThirdStep.svelte';
  import FourthStep from './FourthStep.svelte';
  import Menu from './HeaderMenu.svelte';
  import ScrollArrow from './ScrollArrow.svelte';
  import OrientationAlert from './OrientationAlert.svelte';
  import DeniedAlert from './DeniedAlert.svelte';
  import GrantedAlert from './GrantedAlert.svelte';
  import Preload from './Preload.svelte';
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { TweenMax, TimelineLite } from 'gsap';
  import { getGtag } from '../tracking';

  let step = 2;
  let canScroll = false;
  let sliderRef;

  let firstStep;
  let secondStep;
  let thirdStep;
  let fourthStep;
  let activeStep;
  let orientationAlertRef;
  let deniedAlertRef;
  let orientation = 'vertical';
  let grantedAlertRef;
  let isGranted = false;
  let isDenied = false;
  let supportPassed = false;
  let enableOrientation = true;
  let voiceOnly;
  let gtag;
  let wrapperRef;

  let isPreloadShow = false;

  const dispatch = createEventDispatcher();
  const { pathname } = window.location;

  $: isShownArrow = canScroll && orientation === 'vertical' && step < 4;

  function animationDone() {
    canScroll = true;
  }

  function swipeListener() {
    if (canScroll) {
      goToNextStep();
    }
  }

  function detectDeviceOrientation() {
    window.addEventListener(
      'resize',
      function() {
        const mql = window.matchMedia('(orientation: portrait)');
        if (mql.matches) {
          orientation = 'vertical';
          orientationAlertRef.exitAnimation();
          activeStep.enterAnimation();
        } else {
          orientation = 'horizontal';
          orientationAlertRef.enterAnimation();
          disableOrientation();
          activeStep.setFigureInitialPosition();
        }
      },
      false
    );
  }

  function back() {
    if (supportPassed || (step < 3 && !canScroll) || step === 0 || !step) {
      return;
    }

    canScroll = false;

    step--;

    switch (step) {
      case 1: {
        trackPage('/');
        firstStep.enterAnimation();
        secondStep.reverseExitAnimation();
        activeStep = firstStep;
        break;
      }
      case 2: {
        trackPage('/screen2');
        thirdStep.reverseExitAnimation();
        secondStep.enterAnimation();
        activeStep = secondStep;
        break;
      }
    }
  }

  async function trackPage(page) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.page(page);
  }

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  onMount(async () => {
    if (pathname === '/voice') {
      trackPage('/voice');
      return fourthStep.enterVoiceOnly();
    }

    if (pathname === '/rights' || pathname === '/myths') {
      trackPage(pathname);
      disableScroll();
      step = null;
    }

    goToNextStep();
    detectDeviceOrientation();
    document.addEventListener('swiped-right', back);
    document.addEventListener('swiped-up', swipeListener);
  });

  onDestroy(() => {
    /**
     * Remove orientation listener
     */
    document.addEventListener('swiped-right', back);
    document.removeEventListener('swiped-up', swipeListener);
  });

  function granted() {
    trackEventOnce('intro', 'orientation_allow');
    isGranted = true;
    TweenMax.set(sliderRef, { zIndex: -1 });
    setTimeout(() => {
      grantedAlertRef.enterAnimation();
    });
  }

  function denied() {
    trackEventOnce('intro', 'orientation_deny');
    isDenied = true;
    TweenMax.set(sliderRef, { zIndex: -1 });

    setTimeout(() => {
      deniedAlertRef.enterAnimation();
    });
  }

  function goToMapWithHand() {
    TweenMax.set(sliderRef, { zIndex: 1 });
    trackEventOnce('intro', 'phone_rise');
    goToNextStep();
  }

  function done() {
    goToNextStep();
  }

  function accept() {
    dispatch('accept');
  }

  function watch() {
    TweenMax.to(sliderRef, 0.2, {
      opacity: 0,
      onComplete: () => {
        TweenMax.set(sliderRef, { zIndex: -1 });
      }
    });
    dispatch('watch');
  }

  function support() {
    supportPassed = true;
    trackEventOnce('intro', 'arrow_step3');
  }

  function disableOrientation() {
    enableOrientation = false;
  }

  function disableScroll() {
    canScroll = false;
  }

  function decline() {
    // trackEventOnce('intro', 'notsupport');
    // TweenMax.set(sliderRef, { zIndex: -1 });
    // supportPassed = true;
    dispatch('decline');
    // dispatch('restart-intro');
    canScroll = false;
    enableOrientation = false;
  }

  function goToAudio() {
    TweenMax.to(sliderRef, 0.2, {
      opacity: 0,
      onComplete: () => {
        TweenMax.set(sliderRef, { zIndex: -1 });
        dispatch('goToAudio');
      }
    });
  }
  function goToMain() {
    dispatch('goToMain');
  }
  function goToForm() {
    dispatch('goToForm');
  }
  function goToAbout() {
    dispatch('goToAbout');
  }
  function goToRights() {
    dispatch('goToRights');
  }
  function goToMiths() {
    dispatch('goToMiths');
  }
  function goToCollection() {
    dispatch('goToCollection');
  }

  async function goToNextStep(click) {
    switch (step) {
      // case 0: {
      //   trackPage('/');
      //   firstStep.enterAnimation();
      //   activeStep = firstStep;
      //   break;
      // }
      // case 1: {
      //   trackPage('/screen2');
      //   canScroll = false;
      //   firstStep.exitAnimation();
      //   secondStep.enterAnimation();
      //   activeStep = secondStep;
      //   if (click) {
      //     trackEventOnce('intro', 'arrow_step1');
      //   }
      //   break;
      // }
      case 2: {
        trackPage('/home_mobile');
        canScroll = false;
        // secondStep.exitAnimation();

        activeStep = thirdStep;
        if (click) {
          trackEventOnce('intro', 'arrow_step2');
        }
        break;
      }
      case 3: {
        canScroll = false;
        if (!isDenied && !isGranted) {
          await thirdStep.exitAnimation();
        }
        fourthStep.enterAnimation();
        activeStep = fourthStep;
        break;
      }
      case 4: {
        canScroll = true;
        break;
      }
    }
    step++;
  }
</script>

{#if enableOrientation}
  <OrientationAlert bind:this="{orientationAlertRef}" />
{/if}

{#if isGranted && step < 4}
  <GrantedAlert on:hand-raised="{goToMapWithHand}" bind:this="{grantedAlertRef}" />
{/if}

{#if isDenied && step < 4}
  <DeniedAlert on:swiped="{goToMapWithHand}" bind:this="{deniedAlertRef}" />
{/if}

<div class="slider" bind:this="{sliderRef}">
  {#if step < 4}
    <Menu
      on:goToAbout="{goToAbout}"
      on:goToRights="{goToRights}"
      on:goToMiths="{goToMiths}"
      on:goToCollection="{goToCollection}"
      on:goToMain="{goToMain}"
    />
  {/if}

  <!-- <div class="slider__slide"> -->
  <!-- <FirstStep bind:this="{firstStep}" on:animation-done="{animationDone}" /> -->
  <!-- </div> -->
  <div class="slider__slide">
    <SecondStep bind:this="{secondStep}" on:animation-done="{animationDone}" />
  </div>
  <div class="slider__slide {step === 3 && !isGranted ? 'active-step' : ''}">
    <ThirdStep
      bind:this="{thirdStep}"
      on:support="{support}"
      on:decline="{decline}"
      on:granted="{granted}"
      on:denied="{denied}"
    />
  </div>
  <div class="slider__slide">
    <FourthStep
      bind:this="{fourthStep}"
      on:accept="{accept}"
      on:done="{done}"
      on:animation-done="{animationDone}"
      on:disable-orientation="{disableOrientation}"
      on:watch="{watch}"
      on:disable-scroll="{disableScroll}"
      on:decline="{decline}"
      on:goToAudio="{goToAudio}"
      on:goToForm="{goToForm}"
      on:goToCollection="{goToCollection}"
      on:goToAbout="{goToAbout}"
    />
  </div>
  {#if isShownArrow}
    <ScrollArrow on:click="{() => goToNextStep('click')}" />
  {/if}
</div>
