<style lang="scss">.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background: #fff;
  padding: 15% 20% 20%; }
  .wrapper .text {
    font-size: 1.125rem;
    line-height: 1.5625rem;
    text-transform: uppercase;
    transform: scale(1.2); }
  .wrapper .phone-block {
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .wrapper .phone {
    transform: scale(1.3); }
/*# sourceMappingURL=src/components/DeniedAlert.svelte.map */</style>

<script>
  import gsap, { TweenMax, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH, vh } from '../helpers/vh.js';

  const dispatch = createEventDispatcher();
  let componentRef;
  let timeline;
  let arrow;

  export function setFigureInitialPosition() {
    TweenMax.set(arrow, { y: '0%' });
  }

  function detectSwipe() {
    return new Promise(resolve => {
      document.addEventListener('swiped-up', resolve);
    });
  }

  export async function enterAnimation() {
    TweenMax.set(componentRef, { zIndex: 999 });

    if (timeline) {
      return timeline.restart();
    }

    timeline = new TimelineLite({
      onComplete() {
        timeline.restart();
      },
      useFrames: true,
      immediateRender: true
    });

    timeline.to(
      arrow,
      1,
      {
        opacity: 0,
        y: '-5%'
      },
      0
    );

    await detectSwipe();
    dispatch('swiped');
  }

  export function exitAnimation() {
    TweenMax.set(componentRef, { zIndex: -1 });
    if (timeline) {
      timeline.pause();
    }
  }

  onMount(setFigureInitialPosition);
</script>

<div class="wrapper" bind:this="{componentRef}">
  <div class="text">
    свайпни
    <br />
    угору та
    <br />
    пiдтримай
    <br />
    права ЛГБТК+
    <br />
  </div>
  <div class="phone-block">
    <svg
      class="phone"
      width="88"
      height="159"
      viewBox="0 0 88 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clipPhone)">
        <path
          d="M79.1171 158.528H9.00134C4.26379 158.528 0.473755 154.754 0.473755
          150.036V8.96439C0.473755 4.24629 4.26379 0.47181 9.00134 0.47181H79.1171C83.8546 0.47181
          87.6447 4.24629 87.6447 8.96439V150.036C87.6447 154.636 83.7362 158.528 79.1171 158.528Z"
          fill="white"
          stroke="black"
          stroke-width="0.75"
        ></path>
        <path
          d="M49.2706 135.527C51.8762 138.24 51.5209 142.487 48.6784 144.964C45.8358 147.441 41.3352
          147.087 38.848 144.374C36.2423 141.661 36.5976 137.415 39.4401 134.938C42.2827 132.579
          46.6649 132.815 49.2706 135.527Z"
          stroke="black"
          stroke-width="0.75"
        ></path>
        <path
          d="M6.75098 13.4466C10.8963 13.4466 81.3674 13.4466 81.3674 13.4466H6.75098Z"
          fill="#FAFAFA"
        ></path>
        <path
          d="M6.75098 13.4466C10.8963 13.4466 81.3674 13.4466 81.3674 13.4466"
          stroke="black"
          stroke-width="0.75"
        ></path>
        <path
          d="M6.75098 123.496C10.8963 123.496 81.3674 123.496 81.3674 123.496H6.75098Z"
          fill="#FAFAFA"
        ></path>
        <path
          d="M6.75098 123.496C10.8963 123.496 81.3674 123.496 81.3674 123.496"
          stroke="black"
          stroke-width="0.75"
        ></path>
      </g>
      <g bind:this="{arrow}">
        <path
          d="M51.9 61.9C49.3 59.5 47.2 58.6 44.5 58.5C41.8 58.6 39.7 59.4 37.1 61.9L26.7 72.3L29.5
          75.2L42.6 62.6V89.5H44.5H46.4V62.6L59.5 75.2L62.2999 72.3L51.9 61.9Z"
          stroke="black"
        ></path>
      </g>
      <defs>
        <clipPath id="clipPhone">
          <rect width="88" height="159" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  </div>

</div>
