<style lang="scss">.countdown {
  font-size: 16.25rem;
  color: rgba(255, 255, 255, 0.6); }
  @media (orientation: portrait) {
    .countdown {
      font-size: 6.25rem; } }
  @media (max-height: 320px) {
    .countdown {
      font-size: 10rem; } }
/*# sourceMappingURL=src/components/AudioRecorder/Countdown.svelte.map */</style>

<script>
  import { TimelineMax, TweenLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();

  let countDownRef;

  let count = 9;
  const timeline = new TimelineMax().repeat(9);

  export function start() {
    TweenLite.to(countDownRef, 0.2, {
      opacity: 1,
      onComplete: () => {
        count = 9;
        timeline.add(reduceCount, 1);
        timeline.restart();
      }
    });
  }

  onMount(() => {
    TweenLite.set(countDownRef, { opacity: 0 });
  });

  export function pause() {
    timeline.pause();
  }

  export function restart() {
    count = 9;
    timeline.restart();
  }

  function reduceCount() {
    if (count === 0) {
      return dispatch('countdown-end');
    }

    count -= 1;
  }
</script>

<span bind:this="{countDownRef}" class="countdown">{count}</span>
