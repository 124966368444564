<style>
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: initial;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL01hcC9NYXAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGtCQUFrQjtFQUNsQixNQUFNO0VBQ04sU0FBUztFQUNULE9BQU87RUFDUCxRQUFRO0VBQ1IsZ0JBQWdCO0FBQ2xCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL01hcC9NYXAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4ubWFwIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDA7XG4gIGJvdHRvbTogMDtcbiAgbGVmdDogMDtcbiAgcmlnaHQ6IDA7XG4gIHotaW5kZXg6IGluaXRpYWw7XG59XG4iXX0= */</style>

<script>
  import Map from './js/app.js';
  import { TweenMax } from 'gsap';
  import { onMount } from 'svelte';
  import { getGtag } from '../../tracking';

  let container;
  let map;
  let gtag;

  onMount(async() => {
    gtag = await getGtag();
  })

  export function setIndex() {
    TweenMax.set('.map', { zIndex: 2 });
  }

  export function showHistory(history) {
    if(!map) {
      return;
    }

    map.showHistory(history);
  }

  export function showLine(data, mock) {
    if (!map) {
      return;
    }
    map.showCityLine(data, mock);
  }

  export function init() {
    map = new Map(container);
  }

  export function setInitialPosition(data, history) {
    map.setStartPosition(data, 0.45, history);
  }

  export async function revealMap() {
    setIndex();
    map = new Map(container);
    map.setMapCenter();
    gtag = await getGtag();
    gtag.page('/map');
  }

  export function destroy() {
    if (!map) {
      return;
    }
    map.destroy();
    map = null;
  }

  export async function flyOut() {
    if (!map) {
      return;
    }

    await map.flyout();
  }

  export async function drawRay() {
    // if (!map) {
    //   return;
    // }
    gtag.page('/ukraine');
    return map.drawRay();
  }
</script>

<div class="map" bind:this="{container}"></div>
