<style lang="scss">* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed; }

.hidden {
  visibility: hidden; }

.step-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.wrapper {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 20px; }
  .wrapper .inner-content {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center; }
    .wrapper .inner-content .logo {
      grid-column: span 10;
      width: 676px;
      height: 244px;
      margin: 0 auto 100px; }
      .wrapper .inner-content .logo img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%; }
    .wrapper .inner-content .content {
      grid-column: span 10;
      display: flex;
      flex-direction: column; }
  .wrapper .map-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 20px 0;
    width: 320px;
    height: 90px;
    border: 1px solid #000;
    border-radius: 83px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    padding: 0px 10px;
    margin-left: 40px; }
    .wrapper .map-button:first-child {
      margin-left: 0; }
    .wrapper .map-button .map-button-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      width: 96%;
      height: 80%;
      position: relative;
      border: 1px solid #000;
      font-family: 'Transgender Grotesk';
      justify-content: center; }
      .wrapper .map-button .map-button-inner .map-button-text {
        font-size: 0.6875rem;
        line-height: 1.25rem;
        letter-spacing: 0.25em;
        text-align: center;
        text-transform: uppercase;
        color: #000;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        text-align: center; }

.buttons-wrapper {
  display: flex; }
/*# sourceMappingURL=src/components/DesktopNavigation.svelte.map */</style>

<script>
  import { initSocket } from '../helpers/websocket';
  import { onMount, createEventDispatcher } from 'svelte';
  import { getGtag } from '../tracking';
  import Footer from './Footer.svelte';

  let socket;
  let counter = '';
  let map = "https://www.riznirivni.com.ua" + `/map`;
  let about = "https://www.riznirivni.com.ua" + `/about`;
  let collection = "https://www.riznirivni.com.ua" + `/collection`;
  let gtag;

  const dispatch = createEventDispatcher();

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/dontsupport/museum');
  }

  export async function connectToSocket() {
    socket = initSocket(onMessage);
    let gtag = await getGtag();
    gtag.page('/stub');
  }
  function goToMap() {
    trackEvent('home_desktop', 'watch_map');
    dispatch('goToMap');
  }
  function goToCollection() {
    trackEvent('home_desktop', 'about_collection');
    dispatch('goToCollection');
  }
  function goToAboutDesktop() {
    trackEvent('home_desktop', 'about_project');
    dispatch('goToAboutDesktop');
  }
  function goToDesktopForm() {
    trackEvent('home_desktop', 'to_form');
    dispatch('goToForm');
  }

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }
</script>

<div class="wrapper">
  <div class="inner-content">
    <div class="content">
      <div class="logo">
        <img src="/images/big-logo.svg" alt="" />
      </div>
      <div class="buttons-wrapper">
        <div class="map-button" on:click="{goToCollection}">
          <div class="map-button-inner">
            <span class="map-button-text">Про колекцію рівності</span>
          </div>
        </div>
        <div class="map-button" on:click="{goToAboutDesktop}">
          <div class="map-button-inner">
            <span class="map-button-text">Про проєкт</span>
          </div>
        </div>
        <div class="map-button" on:click="{goToMap}">
          <div class="map-button-inner">
            <span class="map-button-text">
              поглянути на мапу
              <br />
              рівності {counter}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
