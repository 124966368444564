<style lang="scss">.museum-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ebebeb;
  padding: 43px 0 0;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box; }
  .museum-block .block-wrapper {
    padding: 0 20px; }

.lgbt-myths {
  font-family: Montserrat;
  margin-top: 40px; }
  .lgbt-myths .myth {
    display: flex;
    list-style: none;
    position: relative; }
    .lgbt-myths .myth .index {
      font-size: 1.875rem;
      line-height: 2.125rem; }
    .lgbt-myths .myth .description {
      padding: 24px 30px; }
      .lgbt-myths .myth .description .title {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 161.5%; }
      .lgbt-myths .myth .description .text {
        font-size: 0.875rem;
        line-height: 161.5%; }

.background {
  width: auto;
  height: 100%;
  background: white;
  position: absolute;
  left: -2rem;
  right: -2rem;
  z-index: -1; }

.museum-block-buttons {
  display: flex;
  margin-top: 24px;
  padding: 24px; }
  @media (orientation: portrait) {
    .museum-block-buttons {
      flex-direction: column;
      align-items: flex-start; } }
/*# sourceMappingURL=src/components/Final/Myths.svelte.map */</style>

<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { TweenMax } from 'gsap';
  import FinalBack from './FinalBack.svelte';
  import Button from '../Button.svelte';
  import Footer from '../Footer.svelte';
  import { getGtag } from '../../tracking';

  const dispatch = createEventDispatcher();

  let wrapperRef;
  let gtag;
  const lgbtMyths = [
    {
      title: 'Гомосексуальність лікується',
      text:
        "Не можна вилікувати те, що не є хворобою. Ще 1990 року Всесвітня організація охорони здоров’я виключила гомосексуальність з Міжнародної статистичної класифікації хвороб (це основний документ, який відображає сучасні професійні погляди медиків на те, що слід вважати розладом фізичного або психічного здоров’я). Спроби примусово змінити сексуальну орієнтацію — так звана репаративна терапія, — не досягають своєї мети, а натомість шкодять фізичному та психічному здоров'ю «пацієнтівпацієнток». У багатьох сучасних країнах дана терапія заборонена та криміналізована. "
    },
    {
      title: 'Що більше говоримо про гомосексуальність, то більше стає гомосексуальних людей',
      background: true,
      text:
        'Дослідження за різними методологіями свідчать про те, що в кожному суспільстві існує від 5 до 7% гомосексуальних людей. Часто вони не живуть відкрито через страх дискримінації та фізичного насилля, як, наприклад, у консервативних суспільствах. Через це створюється враження, що в якихось країнах більше геїв і лесбійок, а в деяких менше.'
    },
    {
      title: '«Гей-парад» пропагує гомосексуальність',
      text:
        'В Україні не проводяться гей-паради. Натомість проводяться гей-прайди (від англійської “pride” — гідність) чи Марші Рівності — публічні заходи на захист рівноправ’я та людської гідності ЛГБТК+ людей. Марші мають на меті привернути увагу суспільства до дискримінації та злочинів на ґрунті ненависті щодо ЛГБТК+ людей, актуалізувати питання та проблеми, з якими стикається ЛГБТК+ спільнота в конкретній країні. На марш виходять люди, які борються за рівні права для всіх. Серед них дуже багато гетеросексуальних людей та представників різних груп.'
    },
    {
      title: 'В Україні бути ЛГБТК+ людиною — цілком безпечно',
      background: true,
      text:
        'В Україні гомосексуальні люди страждають від насильства. У 2019 році центр «Наш Світ» зафіксував 369 злочинів на ґрунті ненависті. З огляду на відсутність відповідного законодавства, відчуття безкарності злочинців, а також постійний страх неприйняття, постраждалі бояться звертатися до поліції чи повідомляти когось про скоєний злочин, тому про реальну кількість випадків гомофобного насилля невідомо.'
    }
  ];

  onMount(async () => {
    TweenMax.set(wrapperRef, { translateX: '100%' });
  });

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/dontsupport/myths');
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
  }

  export function exitAnimation() {
    TweenMax.to(wrapperRef, 0.4, { translateX: '100%' });
  }

  function back() {
    exitAnimation();
    // dispatch('back');
    history.back();
  }
</script>

<section bind:this="{wrapperRef}" class="museum-block">
  <div class="block-wrapper">
    <FinalBack title="Міфи про ЛГБТК+" on:back="{back}" />
    <ul class="lgbt-myths">
      {#each lgbtMyths as myth, index}
        <li class="myth">
          {#if myth.background}
            <div class="background"></div>
          {/if}
          <!-- <span class="index">{index + 1}</span> -->
          <div class="description">
            <h5 class="title">{myth.title}</h5>
            <span class="text">{myth.text}</span>
          </div>
        </li>
      {/each}
    </ul>
  </div>
  <Footer />
</section>
