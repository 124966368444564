<style lang="scss">svg text {
  font-size: 2.25rem;
  line-height: 2.6875rem; }
/*# sourceMappingURL=src/components/FirstStep.svelte.map */</style>

<script>
  import gsap, { TweenMax, TimelineLite, Back, Elastic } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();
  let text;
  let mask;
  let figure;

  let timeline;
  let infinityTimeline;
  let gtag;
  let entered;

  onMount(() => {
    setFigureInitialPosition();
  });

  export function setFigureInitialPosition() {
    entered = false;
    if (timeline) {
      timeline.kill();
      timeline = null;
    }

    if (infinityTimeline) {
      infinityTimeline.pause();
    }

    const mainFigureParams = { rotate: 220, x: '-10%', y: '150%', scale: 1.2 };
    const textParams = { rotate: -18, x: '10%', y: '150%', opacity: 0, scale: 1.2 };

    TweenMax.set(mask, mainFigureParams);
    TweenMax.set(figure, mainFigureParams);
    TweenMax.set(text, textParams);
  }

  function animateFigure() {
    const mainFigure = [mask, figure];

    timeline = new TimelineLite({
      onComplete() {
        entered = true;
        infinityFigureAnimation();
        dispatch('animation-done');
      }
    });

    timeline
      .to(
        mainFigure,
        1.7,
        {
          rotate: 0,
          x: '-30%',
          y: respondH({ m: '-12%', l: '-8%', xl: '-8%' }, '-18%'),
          ease: Back.easeOut.config(0.3)
        },
        0
      )
      .to(
        text,
        1.7,
        {
          x: respondH({ m: '-2%', l: '0%', xl: '0%' }, '-8%'),
          y: '-8%',
          rotate: 0,
          opacity: 1,
          ease: Back.easeOut.config(0.3)
        },
        0
      );
  }

  export async function enterAnimation() {
    if (entered) {
      return;
    }
    entered = true;
    animateFigure();
  }

  function infinityFigureAnimation() {
    if (infinityTimeline) {
      return infinityTimeline.play();
    }

    let randomDuration = Math.floor(Math.random() * 4) + 5;

    infinityTimeline = new TimelineLite({
      onComplete() {
        randomDuration = Math.floor(Math.random() * 4) + 5;
        infinityTimeline.restart();
      },
      immediateRender: true
    });
    const figures = [mask, figure];

    infinityTimeline
      .to(
        figures,
        4,
        {
          transformOrigin: 'center center',
          rotate: 15
        },
        0
      )
      .to(
        figures,
        4,
        {
          rotate: 0
        },
        randomDuration
      );
  }

  function cleanUp() {
    // timeline.kill();
    // infinityTimeline.kill();
    // infinityTimeline = null;
    // timeline = null;
  }

  export function exitAnimation() {
    timeline = new TimelineLite({ immediateRender: true, onComplete: cleanUp });
    timeline.to(
      [mask, figure, text],
      1.6,
      {
        y: respondH({ xs: '-130%', s: '-130%' }, '-100%')
      },
      0.25
    );
  }
</script>

<svg
  height="100%"
  width="100%"
  viewBox="0 0 320 526"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask id="firstStepMask">
    <path
      bind:this="{mask}"
      d="M-106.033 525.581L-102.705 238.904C-101.046 138.839 -49.8246 56.4331 28.7394
      28.4485C103.331 1.87893 189.823 28.268 253.777 97.5015L459.713 319.585L-106.033 525.581Z"
      fill="white"
    ></path>
  </mask>
  <g mask="url(#firstStepMask)">
    <path
      bind:this="{figure}"
      d="M-106.033 525.581L-102.705 238.904C-101.046 138.839 -49.8246 56.4331 28.7394
      28.4485C103.331 1.87893 189.823 28.268 253.777 97.5015L459.713 319.585L-106.033 525.581Z"
      fill="#FCAACE"
    ></path>
    <text fill="white" bind:this="{text}">
      <tspan x="10%" dy="25%">НЕ</tspan>
      <tspan x="10%" dy="1.2em">ІСНУЄ</tspan>
      <tspan x="10%" dy="1.2em">ДВОХ</tspan>
      <tspan x="10%" dy="1.2em">ОДНА</tspan>
      <tspan x="10%" dy="1.2em">КОВИХ</tspan>
      <tspan x="10%" dy="1.2em">ЛЮДЕЙ</tspan>
    </text>
  </g>
</svg>
