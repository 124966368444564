<style>
svg text {
  font-size: 2.25rem;
  line-height: 2.6875rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NlY29uZFN0ZXAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGtCQUFlO0VBQ2Ysc0JBQWlCO0FBQ25CIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL1NlY29uZFN0ZXAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5zdmcgdGV4dCB7XG4gIGZvbnQtc2l6ZTogMzZweDtcbiAgbGluZS1oZWlnaHQ6IDQzcHg7XG59XG4iXX0= */</style>

<script>
  import { TweenMax, Power4, Sine, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh.js';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();
  let text;
  let figure;
  let mask;
  let timeline;
  let infinityTimeline;
  let gtag;

  onMount(() => {
    setFigureInitialPosition();
  });

  function cleanUp() {
    timeline.kill();
    infinityTimeline.kill();
    infinityTimeline = null;
    timeline = null;
  }

  export function setFigureInitialPosition() {
    if (timeline) {
      timeline.kill();
      timeline = null;
    }

    if (infinityTimeline) {
      infinityTimeline.pause();
    }

    const figureParams = {
      rotate: -50,
      x: '120%',
      y: '180%',
      scale: 1.2
    };
    const textParams = {
      rotate: -18,
      x: '30vw',
      y: '150vh',
      textTransform: 'uppercase',
      scale: 1.2
    };
    TweenMax.set([mask, figure], figureParams);
    TweenMax.set(text, textParams);
  }

  export function reverseExitAnimation() {
    if (timeline) {
      timeline.kill();
      timeline = null;
    }

    if (infinityTimeline) {
      infinityTimeline.pause();
    }

    const figureParams = {
      rotate: -50,
      x: '40%',
      y: '180%',
      scale: 1.2
    };
    const textParams = {
      rotate: -18,
      x: '30vw',
      y: '150vh',
      textTransform: 'uppercase',
      scale: 1.2
    };
    TweenMax.to([mask, figure], 3, figureParams, 2);
    TweenMax.to(text, 3, textParams, 2);
  }

  function animateFigure() {
    const topElement = [mask, figure];

    timeline = new TimelineLite({
      onComplete() {
        infinityFigureAnimation();
        dispatch('animation-done');
      },
      useFrames: true,
      immediateRender: true
    });

    timeline
      .to(topElement, 1.2, {
        rotate: 0,
        x: respondH({ xs: '-5%', l: '-12%', xl: '-12%' }, '-10%'),
        y: respondH({ xs: '-20%', xl: '-10%' }, '-12%'),
        ease: Back.easeOut.config(0.3)
      })
      .to(
        text,
        1.2,
        {
          rotate: 0,
          x: respondH({ xs: '-10%', m: '-2%', l: '-5%', xl: '0%' }, '-8%'),
          y: respondH({ xs: '-15%' }, '-8%'),
          ease: Back.easeOut.config(0.3)
        },
        0.1
      );
  }

  function infinityFigureAnimation() {
    if (infinityTimeline) {
      return infinityTimeline.restart();
    }

    let randomDuration = Math.floor(Math.random() * 4) + 5;

    infinityTimeline = new TimelineLite({
      onComplete() {
        randomDuration = Math.floor(Math.random() * 4) + 5;
        infinityTimeline.restart();
      },
      immediateRender: true
    });

    const topElement = [mask, figure];

    infinityTimeline
      .to(
        topElement,
        4.5,
        {
          transformOrigin: 'center center',
          rotate: 10,
          ease: Sine.easeInOut
        },
        0
      )
      .to(
        topElement,
        4.5,
        {
          rotate: 0,
          ease: Sine.easeInOut
        },
        randomDuration
      );
  }

  export async function enterAnimation() {
    animateFigure();
  }

  export function exitAnimation() {
    timeline = new TimelineLite({ immediateRender: true, onComplete: cleanUp });
    timeline
      .to([mask, figure], 0.7, {
        transformOrigin: 'center',
        rotate: 40,
        x: respondH({ xs: '-50%', s: '-50%' }, '-10%'),
        y: respondH({ xs: '-50%', s: '-50%' }, '-10%')
      })
      .to([mask, figure, text], 1.5, {
        transformOrigin: 'center',
        rotate: 0,
        y: respondH({ xs: '-150%', s: '-150%' }, '-100%'),
        y: respondH({ xs: '-150%', s: '-150%' }, '-100%')
      });
  }
</script>

<svg
  height="100%"
  width="100%"
  viewBox="0 0 320 568"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask id="secondStepMask">
    <path
      bind:this="{mask}"
      d="M264.267 408.297C212.255 504.683 91.0432 540.063 -5.77418 487.818C-102.592 435.573 -138.878
      315.204 -86.8663 218.818C-34.8547 122.433 213.993 80.5555 213.993 80.5555C213.993 80.5555
      316.279 311.912 264.267 408.297Z"
      fill="white"
    ></path>
  </mask>
  <g mask="url(#secondStepMask)">
    <path
      bind:this="{figure}"
      d="M264.267 408.297C212.255 504.683 91.0432 540.063 -5.77418 487.818C-102.592 435.573 -138.878
      315.204 -86.8663 218.818C-34.8547 122.433 213.993 80.5555 213.993 80.5555C213.993 80.5555
      316.279 311.912 264.267 408.297Z"
      fill="#FEB903"
    ></path>
    <text style="font-size: 2rem;" fill="white" bind:this="{text}">
      <tspan x="10%" dy="25%">Ми</tspan>
      <tspan x="10%" dy="1.2em">різні,</tspan>
      <tspan x="10%" dy="1.2em">та має</tspan>
      <tspan x="10%" dy="1.2em">мо рівні</tspan>
      <tspan x="10%" dy="1.2em">права.</tspan>
      <tspan x="10%" dy="1.2em">Погоджу</tspan>
      <tspan x="10%" dy="1.2em">єшся?</tspan>
    </text>
  </g>
</svg>
