<style>
.step-svg {
  position: relative;
  z-index: 6;
  background: transparent;
}
.main-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ededed;
}

.text-wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: #000000;
  width: 200px;

  margin-bottom: 50px;
}

.decline {
  margin-top: 30px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 0;
  color: rgba(0, 0, 0, 0.4);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0ZvdXJ0aFN0ZXAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGtCQUFrQjtFQUNsQixVQUFVO0VBQ1YsdUJBQXVCO0FBQ3pCO0FBQ0E7RUFDRSxrQkFBa0I7RUFDbEIsV0FBVztFQUNYLFlBQVk7RUFDWixtQkFBbUI7QUFDckI7O0FBRUE7RUFDRSxrQkFBa0I7RUFDbEIsVUFBVTtFQUNWLFFBQVE7RUFDUixTQUFTO0VBQ1QsZ0NBQWdDO0FBQ2xDOztBQUVBO0VBQ0Usa0JBQWtCO0VBQ2xCLG1CQUFtQjtFQUNuQixvQkFBZTtFQUNmLGlCQUFpQjtFQUNqQix5QkFBeUI7RUFDekIsY0FBYztFQUNkLFlBQVk7O0VBRVosbUJBQW1CO0FBQ3JCOztBQUVBO0VBQ0UsZ0JBQWdCO0VBQ2hCLGtCQUFrQjtFQUNsQixrQkFBZTtFQUNmLGNBQWM7RUFDZCx5QkFBeUI7QUFDM0IiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvRm91cnRoU3RlcC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5zdGVwLXN2ZyB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgei1pbmRleDogNjtcbiAgYmFja2dyb3VuZDogdHJhbnNwYXJlbnQ7XG59XG4ubWFpbi13cmFwcGVyIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBiYWNrZ3JvdW5kOiAjZWRlZGVkO1xufVxuXG4udGV4dC13cmFwcGVyIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB6LWluZGV4OiAyO1xuICB0b3A6IDUwJTtcbiAgbGVmdDogNTAlO1xuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgtNTAlLCAtNTAlKTtcbn1cblxuLnRpdGxlIHtcbiAgZm9udC1zdHlsZTogbm9ybWFsO1xuICBmb250LXdlaWdodDogbm9ybWFsO1xuICBmb250LXNpemU6IDI1cHg7XG4gIGxpbmUtaGVpZ2h0OiAzMnB4O1xuICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlO1xuICBjb2xvcjogIzAwMDAwMDtcbiAgd2lkdGg6IDIwMHB4O1xuXG4gIG1hcmdpbi1ib3R0b206IDUwcHg7XG59XG5cbi5kZWNsaW5lIHtcbiAgbWFyZ2luLXRvcDogMzBweDtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuICBmb250LXNpemU6IDEycHg7XG4gIGxpbmUtaGVpZ2h0OiAwO1xuICBjb2xvcjogcmdiYSgwLCAwLCAwLCAwLjQpO1xufVxuIl19 */</style>

<script>
  import gsap, { TweenMax, TimelineLite } from 'gsap';
  import { onMount, createEventDispatcher } from 'svelte';
  import Counter from './Counter.svelte';
  import Button from './Button.svelte';
  import QrCode from './QrCode.svelte';
  import Map from './Map/Map.svelte';
  import Popup from './Popup.svelte';
  import { vh, respondH, iOS } from '../helpers/vh';
  import { initSocket } from '../helpers/websocket';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();
  let totalCount = 0;
  let textWrapper;
  let handSvg;
  let mapRef;
  let handRef;
  let circleSvg;
  let mainWrapper;
  let stepSvgRef;
  let popupRef;
  let conterRef;
  let triggered;
  let voiceOnly;

  let qrBlock;

  let socket;
  let animating;
  export let mapOnly;
  let gtag;

  export async function accept() {
    if (triggered) {
      return;
    }
    mapRef.init();
    animating = true;
    socket = await initSocket(onSocketMessage);
    socket.sendAction();
    await socket.once('wave');
    triggered = true;
    dispatch('accept');
    await exitAnimation();
    popupRef.animateArrow();
    // popupRef.openPopup();
    dispatch('done');
  }

  function decline() {
    mapRef.destroy();
    TweenMax.to(handSvg, { opacity: 0 });
    dispatch('decline');
  }

  export async function enterVoiceOnly() {
    voiceOnly = true;
    TweenMax.set(mainWrapper, { zIndex: 5, opacity: 1 });
    socket = await initSocket(onSocketMessage);
    triggered = true;
    conterRef.enterAnimation();
    popupRef.enterAnimation();
  }

  function onSocketMessage(eventData) {
    switch (eventData.type) {
      case 'stats': {
        const regionKey = Object.keys(eventData.data)[0];
      }
      case 'wave': {
        const { data } = eventData;

        if (data.counter) {
          totalCount = data.counter;
        }

        if (data.self) {
          return mapRef.setInitialPosition(
            { region: data.region.toString(), city: data.city },
            data.history
          );
        }

        if (animating) {
          return;
        }

        return mapRef.showLine({ region: data.region.toString(), city: data.city }, data.mock);
      }
      case 'counter': {
        const { data } = eventData;
        console.log(data);
        totalCount = data;
      }
    }
  }

  onMount(async () => {
    setFigureInitialPosition();
  });

  function setFigureInitialPosition() {
    TweenMax.set(mainWrapper, { zIndex: 1, opacity: 0 });
    TweenMax.set(handSvg, { opacity: 0, y: 0, scale: 1 });
  }

  export function enterAnimation() {
    if (mapOnly) {
      return enterOnlyMap();
    }
    TweenMax.set(mainWrapper, { zIndex: 1, opacity: 1 });
    TweenMax.set(handSvg, { opacity: 1, y: '10%', scale: 1.2, transformOrigin: 'center center' });
    TweenMax.to(handSvg, 0.5, { opacity: 1, delay: 0.5 });
    accept();
  }

  async function enterOnlyMap() {
    TweenMax.set(mainWrapper, { zIndex: 1, opacity: 1 });
    TweenMax.set(handSvg, { zIndex: -1, opacity: 0 });
    TweenMax.set(stepSvgRef, { zIndex: -1 });
    socket = await initSocket(({ type, data }) => {
      if (type === 'counter') {
        return (totalCount = data);
      }
      if (type === 'wave') {
        if (data.counter) {
          totalCount = data.counter;
        }

        if (data.self) {
          return;
        }

        return mapRef.showLine(data.region || 32, data.mock);
      }
    });
    socket.send({ action: 'history' });
    socket.once('history').then(result => {
      mapRef.showHistory(result.data.history);
    });
    conterRef.enterAnimation();
    mapRef.revealMap();
  }

  function triggerWatch() {
    mapRef.destroy();
    dispatch('watch');
    dispatch('disable-orientation');
    dispatch('disable-scroll');
    voiceOnly = false;
  }

  function goToAudio() {
    mapRef.destroy();
    dispatch('goToAudio');
    dispatch('disable-orientation');
    dispatch('disable-scroll');
    voiceOnly = false;
  }

  function goToForm() {
    mapRef.destroy();
    dispatch('goToForm');
  }
  function goToAbout() {
    mapRef.destroy();
    dispatch('goToAbout');
  }

  export function showPopup() {
    popupRef.enterAnimation();
  }

  export function exitAnimation() {
    return new Promise(onComplete => {
      const timeline = new TimelineLite({
        onComplete
      });

      mapRef.setIndex();
      timeline
        .to(handSvg, {
          transformOrigin: 'center center',
          rotation: 0,
          scale: 0.15,
          y: respondH(
            { xs: '70vh', s: '60vh', m: '55vh', l: iOS ? '50vh' : '43.5vh', xl: '43.5vh' },
            '80vh'
          ),
          duration: 1,
          onComplete: async () => {
            await mapRef.drawRay();
            setTimeout(() => {
              mapRef.flyOut();
            }, 125);
          }
        })
        .to(
          handSvg,
          {
            y: '-15vh',
            duration: 2,
            onComplete: () => {
              TweenMax.set(stepSvgRef, { zIndex: -1 });
              animating = false;
              conterRef.enterAnimation();
              popupRef.enterScreen();
            }
          },
          2
        );
    });
  }
  function back() {
    dispatch('back');
  }
</script>

<div class="main-wrapper" bind:this="{mainWrapper}">

  <Counter bind:this="{conterRef}" count="{totalCount}" on:back="{back}" />

  {#if mapOnly}
    <QrCode />
  {/if}

  <Popup
    bind:this="{popupRef}"
    on:goToAudio="{goToAudio}"
    on:click="{triggerWatch}"
    on:decline="{decline}"
    on:goToAbout="{goToAbout}"
    {voiceOnly}
    headerText="Переглянь маніфест ”Різні рівні” та запиши свій голос підтримки"
    footerText="не маю часу"
  />
  <Map bind:this="{mapRef}" />
  <svg
    class="step-svg"
    width="100%"
    height="100%"
    viewBox="0 30 320 568"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    bind:this="{stepSvgRef}"
  >
    <path
      bind:this="{handSvg}"
      d="M575.948 -414.909C563.737 -413.735 552.352 -411.33 541.76 -407.792C538.297 -406.635 534.917
      -405.364 531.631 -403.959C525.205 -401.216 519.114 -398.004 513.363 -394.335C478.255 -371.935
      455.726 -332.459 446.585 -278.117C437.188 -222.256 497.302 -123.325 406.65 -67.8402C389.642
      -57.4291 367.332 -48.5469 338.253 -41.7659C336.06 -85.0891 334.413 -134.057 333.089
      -186.336C331.392 -253.18 330.22 -325.447 329.093 -398.325C328.78 -418.569 328.473 -438.861
      328.155 -459.096C328.055 -465.453 327.956 -471.797 327.856 -478.139C326.605 -556.752 325.207
      -633.99 323.055 -703.755C321.963 -739.158 320.678 -772.647 319.119 -803.399C278.729 -798.512
      249.608 -776.231 229.182 -741.953C220.726 -727.764 214.903 -713.429 211.381 -698.99L215.585
      -632.606C221.5 -613.087 229.502 -592.265 239.311 -572.472C231.521 -584.967 223.745 -597.665
      217.108 -608.59L217.361 -604.586C219.358 -598.552 221.609 -592.513 224.119 -586.479C239.57
      -549.315 238.595 -515.746 225.051 -483.196C224.141 -481.01 223.189 -478.827 222.168
      -476.651C214.86 -461.085 204.713 -445.713 192.141 -430.257C191.623 -440.492 191.106 -450.684
      190.591 -460.852C190.585 -460.956 190.58 -461.056 190.574 -461.161C190.403 -464.543 190.232
      -467.926 190.063 -471.299C186.083 -549.879 182.202 -626.527 178.349 -702.601C176.79 -733.396
      175.235 -764.104 173.679 -794.804C112.645 -791.114 69.4547 -748.473 58.7742 -694.912C57.95
      -685.398 57.1044 -675.499 56.2405 -665.313C56.4786 -655.829 57.7068 -646.176 59.9907
      -636.49C62.1554 -627.298 65.2612 -618.076 69.391 -608.936C77.496 -590.996 83.2578 -574.648
      86.5848 -559.416C97.471 -509.581 82.2025 -471.762 36.9987 -429.628C34.9265 -427.696 32.7883
      -425.757 30.5884 -423.805C29.8555 -423.152 29.1196 -422.502 28.3729 -421.845C27.8387 -432.374
      27.3073 -442.894 26.7728 -453.415C26.492 -458.946 26.2111 -464.477 25.9317 -470.003C22.6642
      -534.496 19.4013 -598.94 16.1178 -663.75C14.0288 -705.103 11.9258 -746.596 9.81194
      -788.371C-0.400804 -786.128 -8.29468 -785.531 -15.2797 -782.689C-91.7269 -751.573 -121.701
      -663.628 -86.2191 -575.411C-82.0655 -565.089 -78.7912 -555.199 -76.4016 -545.659C-62.9497
      -491.926 -77.8969 -449.271 -126.244 -402.908C-132.69 -530.135 -138.965 -654.077 -145.297
      -779.075C-189.626 -779.818 -214.598 -755.212 -234.686 -725.132C-272.221 -668.924 -273.135
      -608.598 -247.522 -548.013C-226.698 -498.757 -229.64 -451.642 -248.567 -402.61C-277.285
      -328.21 -303.714 -252.927 -330.955 -177.955C-332.263 -174.362 -333.561 -170.764 -334.868
      -167.172L-334.058 -169.264C-349.275 -68.2808 -349.523 19.4812 -306.112 121.636C-180.527
      417.163 121.452 495.709 238.058 526.852C304.784 544.675 367.884 555.309 423.052
      560.625C457.372 563.932 488.616 565.182 515.756 564.825C567.243 564.149 603.952 557.7 618.781
      548.547C629.451 414.744 628.351 347.579 630.111 180.305C630.152 176.32 630.196 172.251 630.243
      168.137C630.287 164.095 630.336 159.994 630.383 155.832C630.755 123.922 631.238 88.6057
      631.909 51.0842C632.264 31.2676 632.673 10.8374 633.142 -10.0206C636.085 -140.665 641.492
      -288.251 651.999 -408.589C623.65 -411.205 599.407 -417.167 575.948 -414.909Z"
      fill="white"
    ></path>
  </svg>
</div>
