<style lang="scss">.wrapper {
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  width: 100vw;
  background: #fff; }
  .wrapper .header {
    font-size: 0.875rem;
    line-height: 1.4375rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1a1a1a;
    width: 100%;
    padding: 10% 20px 0; }
  .wrapper .footer {
    width: 100%;
    padding: 0 20px;
    font-size: 0.625rem;
    line-height: 0.8125rem;
    color: rgba(26, 26, 26, 0.6);
    font-family: Montserrat;
    padding: 0 20px 10%; }
  .wrapper .images {
    width: 100%;
    height: 65%; }
/*# sourceMappingURL=src/components/GrantedAlert.svelte.map */</style>

<script>
  import gsap, { TweenMax, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();
  let componentRef;
  let timeline;
  let imageWrapper;
  let image;

  export function setFigureInitialPosition() {
    const imageWrapperParams = {
      scale: 0.8,
      y: 10
    };

    const imageParams = {
      y: 40,
      opacity: 0
    };

    TweenMax.set(imageWrapper, imageWrapperParams);
    TweenMax.set(image, imageParams);
  }

  export async function enterAnimation() {
    TweenMax.set(componentRef, { zIndex: 999 });

    if (timeline) {
      return timeline.restart();
    }

    timeline = new TimelineLite({
      onComplete() {
        timeline.restart();
      },
      useFrames: true,
      immediateRender: true
    });

    timeline.to(
      image,
      1,
      {
        y: -40,
        opacity: 1
      },
      0.2
    );

    await detectHandRaise();
    exitAnimation();
    dispatch('hand-raised');
  }

  export function exitAnimation() {
    TweenMax.set(componentRef, { zIndex: -1 });
    if (timeline) {
      timeline.pause();
    }
  }
  function detectHandRaise() {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const sensivity = iOS ? 2.5 : 6;
    return new Promise(resolve => {
      const handRaised = eventData => {
        const getSafePoint = key =>
          (eventData.acceleration || eventData.accelerationIncludingGravity)[key];
        const ax = getSafePoint('x');
        const ay = getSafePoint('y');
        const az = getSafePoint('z');
        if (ax > sensivity || ay > sensivity || az > sensivity) {
          resolve();
          window.removeEventListener('devicemotion', handRaised);
        }
      };

      window.addEventListener('devicemotion', handRaised, true);
      document.addEventListener('swiped-up', resolve);
    });
  }

  onMount(setFigureInitialPosition);
</script>

<div class="wrapper" bind:this="{componentRef}">
  <header class="header">
    Підніми телефон,
    <br />
    щоб пiдтримати рiвнi
    <br />
    права для всіх!
    <br />
  </header>
  <div class="images">
    <svg
      bind:this="{imageWrapper}"
      width="100%"
      height="100%"
      viewBox="0 0 146 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g bind:this="{image}">
        <g clip-path="url(#clip0)">
          <path
            d="M82.2 3.9C79.6 1.5 77.5 0.6 74.8 0.5C72.1 0.6 70 1.4 67.4 3.9L57 14.3L59.8 17.2L72.9
            4.6V31.5H74.8H76.7V4.6L89.8 17.2L92.6 14.3L82.2 3.9Z"
            stroke="black"
          ></path>
        </g>
        <g clip-path="url(#clip1)">
          <g clip-path="url(#clip2)">
            <path
              d="M30.2 3.9C27.6 1.5 25.5 0.6 22.8 0.5C20.1 0.6 18 1.4 15.4 3.9L5 14.3L7.8 17.2L20.9
              4.6V31.5H22.8H24.7V4.6L37.8 17.2L40.6 14.3L30.2 3.9Z"
              stroke="black"
            ></path>
          </g>
        </g>
        <path
          d="M132.9 3.9C130.3 1.5 128.2 0.6 125.5 0.5C122.8 0.6 120.7 1.4 118.1 3.9L107.7 14.3L110.5
          17.2L123.6 4.6V31.5H125.5H127.4V4.6L140.5 17.2L143.3 14.3L132.9 3.9Z"
          stroke="black"
        ></path>
        <g clip-path="url(#clip3)">
          <path
            d="M83.2 213.9C80.6 211.5 78.5 210.6 75.8 210.5C73.1 210.6 71 211.4 68.4 213.9L58
            224.3L60.8 227.2L73.9 214.6V241.5H75.8H77.7V214.6L90.8 227.2L93.6 224.3L83.2 213.9Z"
            stroke="black"
          ></path>
        </g>
        <g clip-path="url(#clip4)">
          <g clip-path="url(#clip5)">
            <path
              d="M31.2 213.9C28.6 211.5 26.5 210.6 23.8 210.5C21.1 210.6 19 211.4 16.4 213.9L6
              224.3L8.8 227.2L21.9 214.6V241.5H23.8H25.7V214.6L38.8 227.2L41.6 224.3L31.2 213.9Z"
              stroke="black"
            ></path>
          </g>
        </g>
        <path
          d="M133.9 213.9C131.3 211.5 129.2 210.6 126.5 210.5C123.8 210.6 121.7 211.4 119.1
          213.9L108.7 224.3L111.5 227.2L124.6 214.6V241.5H126.5H128.4V214.6L141.5 227.2L144.3
          224.3L133.9 213.9Z"
          stroke="black"
        ></path>
        <g clip-path="url(#clip6)">
          <path
            d="M133.8 91C136.2 88.3 136 84.1 133.3 81.7L91.3 44.2C88.6 41.8 84.4 42 82 44.7L72.6
            55.9L71 54.5C66.8 50.8 60.4 51.3 56.8 55.5L23.2 94.7C16.5 102.6 12.9 109 10 118.9L10.4
            118.5C9.80001 120.9 9.40001 123.4 9.20001 126.2L2.20001 134.1C-0.199993 136.8
            6.67572e-06 141 2.70001 143.4L38.7 175.5C40.3 177.1 42 178.7 43.6 179.9L44.7 180.9C46.3
            182.3 48.4 182.8 50.4 182.4C55.8 185.8 59.5 187.7 59.5 187.7C88.1 197.9 123.9 195.1
            123.9 195.1L123.8 194.8L123.9 101.9L133.8 91Z"
            fill="white"
          ></path>
          <path d="M59.5 187.8C88.1 198 123.9 195.2 123.9 195.2L59.5 187.8Z" fill="white"></path>
          <path d="M59.5 187.8C88.1 198 123.9 195.2 123.9 195.2" stroke="black"></path>
          <path
            d="M16.5 122.7C16.5 142.4 23.8 157.8 32.8 168.7C36 172.6 40.7 178.1 45.1 180.8"
            fill="white"
          ></path>
          <path
            d="M16.5 122.7C16.5 142.4 23.8 157.8 32.8 168.7C36 172.6 40.7 178.1 45.1 180.8"
            stroke="black"
          ></path>
          <path
            d="M88.6 142.3L133.8 91C136.2 88.3 136 84.1 133.3 81.7L91.3 44.2C88.6 41.8 84.4 42 82
            44.7L76 51.4"
            fill="white"
          ></path>
          <path
            d="M88.6 142.3L133.8 91C136.2 88.3 136 84.1 133.3 81.7L91.3 44.2C88.6 41.8 84.4 42 82
            44.7L76 51.4"
            stroke="black"
          ></path>
          <path
            d="M24.2 109.4L2.20001 134.1C-0.199993 136.8 6.67572e-06 141 2.70001 143.4L44.7
            180.9C47.4 183.3 51.6 183.1 54 180.4L73.6 159.3"
            fill="white"
          ></path>
          <path
            d="M24.2 109.4L2.20001 134.1C-0.199993 136.8 6.67572e-06 141 2.70001 143.4L44.7
            180.9C47.4 183.3 51.6 183.1 54 180.4L73.6 159.3"
            stroke="black"
          ></path>
          <path d="M116.5 91.6L123.8 195" stroke="black"></path>
          <path
            d="M123.8 195L124 79.9C124 79.9 100 79.1 107 103.2C109.2 110.9 108.3 116.4 106 121C104.9
            123.1 103.3 124.8 101.4 126.2C96 130.2 82.7 141.2 73.5 159.4"
            fill="white"
          ></path>
          <path
            d="M123.8 195L124 79.9C124 79.9 100 79.1 107 103.2C109.2 110.9 108.3 116.4 106 121C104.9
            123.1 103.3 124.8 101.4 126.2C96 130.2 82.7 141.2 73.5 159.4"
            stroke="black"
          ></path>
          <path
            d="M24.4 106.3C26.1 107.1 27.7 108.4 28.7 110.6C39.9 133.6 57.7 120.5 57.7 120.5L31.5
            95.5C31.5 95.5 37.3 90.3 40.8 96.2C53.7 118.3 70.6 104.7 70.6 104.7L44.2 80.3C44.2 80.3
            49.5 75.7 53 81.6C65.9 103.7 82.7 89.9 82.7 89.9L56.1 65.8C58.9 63.6 62.2 62.5 65.3
            67.1C79.6 88.3 95 75.9 95 75.9L70.8 54.4C66.6 50.7 60.2 51.2 56.6 55.4L23.2 94.7C16.5
            102.6 12.9 109 10 118.9"
            fill="white"
          ></path>
          <path
            d="M24.4 106.3C26.1 107.1 27.7 108.4 28.7 110.6C39.9 133.6 57.7 120.5 57.7 120.5L31.5
            95.5C31.5 95.5 37.3 90.3 40.8 96.2C53.7 118.3 70.6 104.7 70.6 104.7L44.2 80.3C44.2 80.3
            49.5 75.7 53 81.6C65.9 103.7 82.7 89.9 82.7 89.9L56.1 65.8C58.9 63.6 62.2 62.5 65.3
            67.1C79.6 88.3 95 75.9 95 75.9L70.8 54.4C66.6 50.7 60.2 51.2 56.6 55.4L23.2 94.7C16.5
            102.6 12.9 109 10 118.9"
            stroke="black"
          ></path>
          <path
            d="M33.8 156C36.8376 156 39.3 153.538 39.3 150.5C39.3 147.462 36.8376 145 33.8
            145C30.7624 145 28.3 147.462 28.3 150.5C28.3 153.538 30.7624 156 33.8 156Z"
            fill="white"
            stroke="black"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect x="57" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip1">
            <rect x="5" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip2">
            <rect x="5" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip3">
            <rect x="58" y="210" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip4">
            <rect x="6" y="210" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip5">
            <rect x="6" y="210" width="36.2" height="31.4" fill="white"></rect>
          </clipPath>
          <clipPath id="clip6">
            <rect y="42" width="135.9" height="154" fill="white"></rect>
          </clipPath>
        </defs>
      </g>
    </svg>
  </div>
  <footer class="footer">
    Ми не запитуємо і не записуємо ваші персональні дані. Місцезнаходження на мапі буде визначатися
    через IP-адресу з точністю 30 км.
  </footer>
</div>
