<style lang="scss">.partners-block {
  background-color: #e5e5e5;
  padding: 24px;
  height: 100%;
  overflow: auto; }

a {
  text-decoration: none; }

@media (orientation: portrait) {
  .partner-link-block {
    display: none; } }

.partner-link,
.partner-image {
  width: 50px;
  height: 50px; }

.social-link-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto; }

.partner-links {
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end; }
  .partner-links--portrait {
    flex-direction: row;
    align-items: center; }
    .partner-links--portrait .partner-link--social {
      display: inline-flex;
      margin-left: 18px; }
  @media (orientation: landscape) {
    .partner-links--portrait {
      display: none; }
    .partner-links--landscape {
      display: flex; } }
  @media (orientation: portrait) {
    .partner-links--portrait {
      display: flex; }
    .partner-links--landscape {
      display: none; } }

.partner-description {
  margin: 0 68px 0 16px; }
  @media (orientation: portrait) {
    .partner-description {
      margin: 16px 0 0 0; } }
  .partner-description .partner-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem; }
  .partner-description .partner-contacts {
    display: flex;
    flex-direction: column;
    margin-top: 10px; }
    .partner-description .partner-contacts .contact {
      display: flex;
      align-items: center;
      margin-bottom: 5px; }
      .partner-description .partner-contacts .contact .text {
        font-family: 'Montserrat', sans-serif;
        margin-left: 10px;
        color: #000;
        font-size: 0.625rem;
        line-height: 161%; }

.partner-block {
  display: flex;
  margin-bottom: 44px;
  padding: 0 24px; }
  @media (orientation: portrait) {
    .partner-block {
      flex-direction: column; } }
/*# sourceMappingURL=src/components/Final/Partners.svelte.map */</style>

<script>
  import FinalBack from './FinalBack.svelte';
  import { TweenMax } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getGtag } from '../../tracking';

  const dispatch = createEventDispatcher();

  let gtag;
  let wrapperRef;

  const partners = [
    {
      link: 'https://lgbt.com.ua/',
      imageType: 'png',
      text: `Асоціація ЛГБТК+ «ЛІГА» — громадська організація, що займається питаннями та проблемами захисту прав і збереження здоров'я людей з ЛГБТ-спільноти, підтримує батьків та ЛГБТ-дітей. Асоціація відкрила громадські центри для ЛГБТ-людей в Миколаєві, Одесі, Херсоні та Києві.`,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/LIGA.Odessa/'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/ligalgbt_od/'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'ligalgbtod@gmail.com'
        },
        {
          type: 'phone',
          text: '+380 50 318 48 43 | підрозділ в Одесі '
        }
      ]
    },
    {
      link: 'http://genderz.org.ua/',
      imageType: 'svg',
      text: `Гендер Зед — правозахисна організація, що працює з широким колом питань, які стосуються сексуальної орієнтації та гендерної ідентичності, гендерної рівності, подоланням стереотипів стосовно ЛГБТ-спільноти, жінок та інших дискримінованих груп.`,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/genderzed/'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/gender.z/'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'zp.gender@gmail.com'
        }
      ]
    },
    {
      link: 'https://kyivpride.org/',
      imageType: 'svg',
      text: `KyivPride — громадська організація, що створює найбільшу та найвидимішу ЛГБТ+ подію в Україні, об'єднуюча національна платформа, що згуртовує загальнодержавні інституції, національні та регіональні громадські ініціативи, мистецтво, бізнес та інших союзників заради видимості ЛГБТ+ та спільних дій щодо вирішення проблем, з якими стикається спільнота. `,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/kyivpride/'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/kyivpride/'
        },
        {
          type: 'twitter',
          link: 'https://twitter.com/kyivpride'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'info@kyivpride.org'
        }
      ]
    },
    {
      imageType: 'svg',
      text: `«Освітній центр з прав людини у Львові» — громадська організація, створена людьми різних професій, які мають добру репутацію у місті. Забезпечує освітні заходи з популяризації цінностей прав людини та рівності у Львові та Україні.`,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/echrlviv/'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/echrlviv/'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'echr.lviv@gmail.com'
        }
      ]
    },
    {
      link: 'https://boinsha.com/',
      imageType: 'svg',
      text: `БО «Інша» — феміністична ЛГБТ-інклюзивна організація у місті Херсон. Працює над просвітницькими, адвокаційними та творчими проєктами, які просувають цінності ЛГБТ-спільноти.`,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/boinsha/'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'boinshar@gmail.com'
        },
        {
          type: 'phone',
          text: '+380 66 902 48 50 | офіс'
        },
        {
          type: 'phone',
          text: '+380 50 494 94 24 | Марина Устимова'
        }
      ]
    },
    {
      link: 'http://sphere.org.ua/',
      imageType: 'png',
      text: `Громадська організація Харківське Жіноче Об’єднання “Сфера” — українська громадська організація, створена задля посилення впливу жінок і жіночих громад у суспільстві шляхом організації майданчиків для просвіти та самореалізації жінок.`,
      links: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/spherewa/'
        }
      ],
      contacts: [
        {
          type: 'email',
          text: 'wa.sphere.kh@gmail.com'
        }
      ]
    }
  ];

  onMount(async () => {
    TweenMax.set(wrapperRef, { translateX: '100%' });
  });

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/ourfriends');
    document.body.className = 'scroll';
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
  }

  export function exitAnimation() {
    document.body.className = '';
    TweenMax.to(wrapperRef, 0.4, { translateX: '100%' });
  }

  function back() {
    exitAnimation();
    dispatch('back');
  }

  function getPhone(text) {
    let phone = text.replace(/[^0-9]/g, '');
    return Number(phone);
  }
</script>

<section class="partners-block" bind:this="{wrapperRef}">
  <FinalBack title="Наші партнери" on:back="{back}" />
  {#each partners as partner, i}
    <div class="partner-block">
      <div class="partner-link-block">
        {#if partner.link}
          <a class="partner-link" href="{partner.link}" target="_blank">
            <img
              class="partner-image"
              src="{`/images/partners/partner_${i + 1}.${partner.imageType}`}"
              alt="{`Partner image ${i + 1}`}"
            />
          </a>
        {:else}
          <img
            class="partner-image"
            src="{`/images/partners/partner_${i + 1}.${partner.imageType}`}"
            alt="{`Partner image ${i + 1}`}"
          />
        {/if}
      </div>

      <div class="partner-links partner-links--portrait">
        {#if partner.link}
          <a class="partner-link" href="{partner.link}" target="_blank">
            <img
              class="partner-image"
              src="{`/images/partners/partner_${i + 1}.${partner.imageType}`}"
              alt="{`Partner image ${i + 1}`}"
            />
          </a>
        {:else}
          <img
            class="partner-image"
            src="{`/images/partners/partner_${i + 1}.${partner.imageType}`}"
            alt="{`Partner image ${i + 1}`}"
          />
        {/if}

        <div class="social-link-wrapper">
          {#each partner.links as link}
            <a class="partner-link--social" href="{link.link}" target="_blank">
              <svg
                fill="none"
                height="30"
                viewBox="0 0 30 30"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
              >
                {#if link.type === 'facebook'}
                  <path
                    d="m26 2.49998h-22.00004c-.82843 0-1.5.67157-1.5 1.5v22.00002c0 .8284.67157 1.5
                    1.5 1.5h11.50004v-8h-3v-4h3v-3c0-2.76143 2.2386-5.00001
                    5-5.00001h3v4.00001h-2c-1.1046 0-2 .8954-2 2v2h4l-.5 4h-3.5v8h6.5c.8284 0
                    1.5-.6716 1.5-1.5v-22.00002c0-.82843-.6716-1.5-1.5-1.5z"
                    stroke="#303c42"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                  ></path>
                {/if}
                {#if link.type === 'instagram'}
                  <g
                    stroke="#303c42"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                  >
                    <path
                      d="m27.5 21.5c0 3.3137-2.6863 6-6 6h-13.00004c-3.31371
                      0-6-2.6863-6-6v-13.00001c0-3.31371 2.68629-6.00001 6-6.00001h13.00004c3.3137 0
                      6 2.6863 6 6.00001z"
                    ></path>
                    <path
                      d="m14.9782 21.5c3.5899 0 6.5-2.9102 6.5-6.5
                      0-3.5899-2.9101-6.50002-6.5-6.50002-3.5898 0-6.49997 2.91012-6.49997 6.50002 0
                      3.5898 2.91017 6.5 6.49997 6.5z"
                    ></path>
                    <path
                      d="m21.9999 6.49999c.2762 0 .5-.22386.5-.50001 0-.27614-.2238-.5-.5-.5-.2761
                      0-.5.22386-.5.5 0 .27615.2239.50001.5.50001z"
                    ></path>
                  </g>
                {/if}
                {#if link.type === 'twitter'}
                  <path
                    d="m2.39134 4.70995c2.42783 2.9597 6.77469 5.75245 12.60866
                    6.05275-.3217-1.28517-.3527-4.77335 2.191-6.22303 1.1377-.64845 2.2147-1.03966
                    3.4834-1.03966 1.5942 0 2.9026.52463 4.2944 1.80097 1.1328-.15836 3.249-1.03146
                    3.7511-1.37442-.3269 1.20345-1.6348 2.74872-2.5796 3.11561.8676-.00258
                    2.7753-.50004 3.3597-.79399-.8109 1.25119-2.227 2.44975-3.0019 2.94722.8972
                    7.7102-6.261 17.295-16.54152 17.3046-2.93585.0027-6.12633-.5957-9.456542-2.4213
                    3.285172.3693 6.805052-.6523
                    8.826092-2.4211-2.54884-.0553-5.13038-1.7043-6.30435-4.2369 1.27705.3818
                    2.45446.5753 3.15217 0-1.18939-.1282-5.04348-2.1546-5.04348-6.0792.88569.7035
                    2.1609 1.4305 3.15218 1.237-1.62203-.9221-3.979308-4.4339-1.89131-7.86855z"
                    stroke="#303c42"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                  ></path>
                {/if}
              </svg>
            </a>
          {/each}
        </div>
      </div>

      <div class="partner-description">
        <p class="partner-text">{partner.text}</p>
        <div class="partner-contacts">
          {#each partner.contacts as contact, i}
            <div class="contact">
              {#if contact.type === 'email'}
                <img class="email-icon" src="/images/email.svg" alt="email-icon" />
                <a class="text" href="{`mailto:${contact.text}`}">{contact.text}</a>
              {:else}
                <img class="phone-icon" src="/images/phone.svg" alt="phone-icon" />
                <a class="text" href="{`tel:+${getPhone(contact.text)}`}">{contact.text}</a>
              {/if}
            </div>
          {/each}
        </div>
      </div>

      <div class="partner-links partner-links--landscape">
        {#each partner.links as link}
          <a href="{link.link}" target="_blank">
            <svg
              fill="none"
              height="30"
              viewBox="0 0 30 30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              {#if link.type === 'facebook'}
                <path
                  d="m26 2.49998h-22.00004c-.82843 0-1.5.67157-1.5 1.5v22.00002c0 .8284.67157 1.5
                  1.5 1.5h11.50004v-8h-3v-4h3v-3c0-2.76143 2.2386-5.00001
                  5-5.00001h3v4.00001h-2c-1.1046 0-2 .8954-2 2v2h4l-.5 4h-3.5v8h6.5c.8284 0
                  1.5-.6716 1.5-1.5v-22.00002c0-.82843-.6716-1.5-1.5-1.5z"
                  stroke="#303c42"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                ></path>
              {/if}
              {#if link.type === 'instagram'}
                <g
                  stroke="#303c42"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <path
                    d="m27.5 21.5c0 3.3137-2.6863 6-6 6h-13.00004c-3.31371
                    0-6-2.6863-6-6v-13.00001c0-3.31371 2.68629-6.00001 6-6.00001h13.00004c3.3137 0 6
                    2.6863 6 6.00001z"
                  ></path>
                  <path
                    d="m14.9782 21.5c3.5899 0 6.5-2.9102 6.5-6.5
                    0-3.5899-2.9101-6.50002-6.5-6.50002-3.5898 0-6.49997 2.91012-6.49997 6.50002 0
                    3.5898 2.91017 6.5 6.49997 6.5z"
                  ></path>
                  <path
                    d="m21.9999 6.49999c.2762 0 .5-.22386.5-.50001 0-.27614-.2238-.5-.5-.5-.2761
                    0-.5.22386-.5.5 0 .27615.2239.50001.5.50001z"
                  ></path>
                </g>
              {/if}
              {#if link.type === 'twitter'}
                <path
                  d="m2.39134 4.70995c2.42783 2.9597 6.77469 5.75245 12.60866
                  6.05275-.3217-1.28517-.3527-4.77335 2.191-6.22303 1.1377-.64845 2.2147-1.03966
                  3.4834-1.03966 1.5942 0 2.9026.52463 4.2944 1.80097 1.1328-.15836 3.249-1.03146
                  3.7511-1.37442-.3269 1.20345-1.6348 2.74872-2.5796 3.11561.8676-.00258
                  2.7753-.50004 3.3597-.79399-.8109 1.25119-2.227 2.44975-3.0019 2.94722.8972
                  7.7102-6.261 17.295-16.54152 17.3046-2.93585.0027-6.12633-.5957-9.456542-2.4213
                  3.285172.3693 6.805052-.6523
                  8.826092-2.4211-2.54884-.0553-5.13038-1.7043-6.30435-4.2369 1.27705.3818
                  2.45446.5753 3.15217 0-1.18939-.1282-5.04348-2.1546-5.04348-6.0792.88569.7035
                  2.1609 1.4305 3.15218 1.237-1.62203-.9221-3.979308-4.4339-1.89131-7.86855z"
                  stroke="#303c42"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                ></path>
              {/if}
            </svg>
          </a>
        {/each}
      </div>
    </div>
  {/each}
</section>
