<style lang="scss">* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed; }

.hidden {
  visibility: hidden; }

.step-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.counter-wrapper {
  padding: 15px 16px;
  position: absolute;
  opacity: 0;
  background: #ffffff;
  width: 100%; }
  @media (min-width: 800px) {
    .counter-wrapper {
      background: transparent;
      position: relative;
      max-width: 1050px;
      margin: 0 auto;
      padding: 60px 20px 0; } }
  .counter-wrapper-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 450px;
    margin: 0 auto; }
    @media (min-width: 800px) {
      .counter-wrapper-inner {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start; } }
  .counter-wrapper .count {
    font-size: 2.8125rem;
    line-height: 3.375rem;
    text-align: right;
    text-transform: uppercase;
    color: #000000; }
    @media (max-width: 780px) {
      .counter-wrapper .count {
        font-size: calc(3vw + 4vh);
        text-align: right; } }
    @media (min-width: 800px) {
      .counter-wrapper .count {
        font-size: 1.5rem;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000000;
        text-align: left; } }
  .counter-wrapper .description {
    margin-left: 14px;
    font-size: 0.5rem;
    line-height: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000; }
    @media (min-width: 800px) {
      .counter-wrapper .description {
        display: none; } }

.arrow-btn {
  font-size: 1.5rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
  margin-right: 6px; }
/*# sourceMappingURL=src/components/Counter.svelte.map */</style>

<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  import { TweenMax } from 'gsap';
  import ArrowBack from './ArrowBack.svelte';
  export let count = 0;

  let counterRef;
  let isMobile;
  isMobile = !window.matchMedia('(min-width: 1024px)').matches;

  export function enterAnimation() {
    TweenMax.set(counterRef, { zIndex: 3 });
    TweenMax.to(counterRef, 1, { opacity: 1 });
  }

  function back() {
    dispatch('back');
  }
</script>

<div class="counter-wrapper" bind:this="{counterRef}">
  <div class="counter-wrapper-inner">
    {#if !isMobile}
      <div class="arrow-btn">
        <ArrowBack on:back="{back}" title="Мапа рівності:" />
      </div>
    {/if}
    <div class="count">{count}</div>
    <div class="description">
      різних та рівних,
      <br />
      які підтримують
      <br />
      права ЛГБТК+
    </div>
  </div>
</div>
