<style lang="scss">.timeline-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ededed;
  display: flex;
  align-items: center;
  /* .timeline-recording {
    position: absolute;
    top: -25px;
    transform: translateX(-35%);
    min-width: 200px;

    @media (orientation: portrait) {
      min-width: auto;
    }

    .text {
      opacity: 0;
      color: white;
      text-transform: uppercase;
      font-size: 14px;
    }
  } */
  /* .timeline-recording-line {
    position: absolute;
    background-color: #feb903;
    height: 3px;
    top: -1px;
    z-index: 150;
  } */ }
  @media (orientation: landscape) {
    .timeline-wrapper {
      overflow: auto; } }
  .timeline-wrapper .video-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .timeline-wrapper .pause-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(63, 63, 63, 0.8);
    z-index: -1;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .timeline-wrapper .play-btn {
    position: absolute; }
  .timeline-wrapper .video {
    display: flex;
    width: 100%;
    height: 100%; }
  .timeline-wrapper .lines-wrapper {
    position: absolute;
    right: 18px;
    top: 0;
    width: 6px;
    height: 100vh;
    z-index: 999; }
    .timeline-wrapper .lines-wrapper .lines {
      position: absolute;
      height: 100px; }
  .timeline-wrapper .timeline {
    position: absolute;
    display: inline-block;
    width: 90%;
    height: 1px;
    z-index: 30;
    left: 5%;
    bottom: 7%;
    background: rgba(233, 233, 233, 0.5);
    opacity: 0; }
    @media (orientation: portrait) {
      .timeline-wrapper .timeline {
        min-width: auto;
        bottom: 38%; } }
  .timeline-wrapper .timeline-progress,
  .timeline-wrapper .timeline-range {
    position: absolute;
    left: 0;
    height: 3px;
    top: -1px;
    display: inline-block;
    background-color: #94b6c5;
    transition: width 0.1s linear; }
  .timeline-wrapper .timeline-range {
    height: 40px;
    width: 100%;
    z-index: 10;
    top: -20px;
    opacity: 0; }
  .timeline-wrapper .only-horizontal {
    display: inline; }
    @media (orientation: portrait) {
      .timeline-wrapper .only-horizontal {
        display: none; } }
/*# sourceMappingURL=src/components/TimeLine.svelte.map */</style>

<script>
  import { TweenMax, TimelineLite, TimelineMax } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import VideoRotateOverlay from './VideoRotateOverlay.svelte';
  import { getGtag } from '../tracking';

  const dispatch = createEventDispatcher();
  let videoRotateOverlayRef;
  let mainWrapper;
  let recordingTextStartPosition;

  // let recordingLength;
  let recordingPause = 14.8;
  let recordingDuration = 10;

  let videoRef;
  let timelineRef;
  let textRef;
  let pauseRef;
  let playedPercent = 0;

  let recorded = false;
  let gtag;
  // let inputSlider;
  let inited = false;

  let watched;
  let paused = true;
  export let alreadyRecorded = false;

  function recordSound() {
    TweenMax.set(mainWrapper, { zIndex: -1, opacity: 0 });
    dispatch('record-sound');
  }

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  let trackPercent = 0;

  onMount(() => {
    videoRef.onplaying = () => {
      paused = false;
    };
    videoRef.onpause = () => {
      paused = true;
    };
    videoRef.ontimeupdate = function() {
      if (!videoRef) {
        return;
      }

      playedPercent = parseFloat(((videoRef.currentTime / videoRef.duration) * 100).toFixed(2));

      if (playedPercent >= 1 && !trackPercent) {
        trackPercent = 1;
        trackEventOnce('video', 'video_percent_001');
      }

      if (playedPercent >= 25 && trackPercent < 25) {
        trackPercent = 25;
        trackEventOnce('video', 'video_percent_025');
      }

      if (playedPercent >= 50 && trackPercent < 50) {
        trackPercent = 50;
        trackEventOnce('video', 'video_percent_050');
      }

      if (playedPercent >= 75 && trackPercent < 75) {
        trackPercent = 75;
        trackEventOnce('video', 'video_percent_075');
      }

      // if (videoRef.currentTime >= recordingPause && !recorded) {
      //   recordSound();
      // }
    };

    videoRef.onended = function() {
      trackPercent = 100;
      trackEventOnce('video', 'video_percent_100');

      if (!alreadyRecorded) {
        toRecording();
      } else {
        TweenMax.set(mainWrapper, { zIndex: -1, opacity: 0 });
        dispatch('video-end');
      }
      watched = true;
    };

    videoRef.oncanplay = function() {
      recordingTextStartPosition = ((recordingPause / videoRef.duration) * 100).toFixed(2);
      // recordingLength = ((recordingDuration / videoRef.duration) * 100).toFixed(2);
    };

    TweenMax.set(mainWrapper, { zIndex: -1, opacity: 0 });
  });

  function toggleVideo() {
    videoRef.play();
    console.log('clicked togle animation');
    // if (isPaused) {
    //   videoRef.play();
    //   TweenMax.set(pauseRef, { zIndex: -1, opacity: 0 });
    //   TweenMax.to(textRef, 0.3, { opacity: 0 });
    // } else {
    //   videoRef.pause();
    //   TweenMax.to(textRef, 0.3, { opacity: 1 });
    //   TweenMax.set(pauseRef, { zIndex: 20 });
    //   TweenMax.to(pauseRef, 0.3, { opacity: 1 });
    // }

    // isPaused = !isPaused;
  }

  export function afterRecordAudioStep() {
    recorded = true;
    TweenMax.set(mainWrapper, { zIndex: 1, opacity: 1 });
    videoRef.currentTime = 0;
    videoRef.play();
  }

  async function openRotateOverlay() {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      TweenMax.to(timelineRef, 0.3, { opacity: 1 });
      return playVideo();
    }

    listenForRotate();
    await videoRotateOverlayRef.enterAnimation();
    removeRotateListener();

    TweenMax.to(timelineRef, 0.3, { opacity: 1 });
  }

  function rotateListener() {
    const mql = window.matchMedia('(orientation: portrait)');

    if (mql.matches) {
      return;
    }

    videoRotateOverlayRef.exitAnimation();
    removeRotateListener();
    TweenMax.to(timelineRef, 0.3, { opacity: 1 });
  }

  function removeRotateListener() {
    window.removeEventListener('resize', rotateListener);
  }

  function listenForRotate() {
    window.addEventListener('resize', rotateListener, false);
  }

  function toRecording() {
    recordSound();
  }

  async function videoSlide() {
    // await videoRef.pause();
    // const slideTo = (await videoRef.duration) * (inputSlider.value / 100).toFixed(2);
    // videoRef.currentTime = slideTo;
    // videoRef.play();
  }

  // function slideTimeUpdate() {
  //   inputSlider.value = playedPercent;
  // }

  async function playVideo() {
    await videoRef.play();
    paused = false;
  }

  export async function enterAnimation() {
    TweenMax.set(mainWrapper, { zIndex: 1, opacity: 1 });
    dispatch('disable-orientation');
    openRotateOverlay();
    gtag = await getGtag();
    gtag.page('/video');
  }

  export function exitAnimation() {}
</script>

<div class="timeline-wrapper" bind:this="{mainWrapper}">
  <VideoRotateOverlay bind:this="{videoRotateOverlayRef}" />
  <div class="video-wrapper">
    {#if !watched}
      <video
        class="video"
        poster="images/video-poster.jpg"
        src="/ndi_it_v2.mp4"
        preload
        controls
        on:click="{toggleVideo}"
        bind:this="{videoRef}"
      ></video>
    {/if}
  </div>
  <!-- <div class="timeline" bind:this="{timelineRef}">
    <input
      class="timeline-range"
      bind:this="{inputSlider}"
      on:change="{videoSlide}"
      type="range"
      min="0"
      max="100"
      value="0"
      step="1"
    />
    <span class="timeline-progress" style="width: {playedPercent}%;"></span> -->
  <!-- <div class="timeline-recording" style="left: {recordingTextStartPosition}%;">
      <span class="text" bind:this="{textRef}">
        ЗАПИС
        <span class="only-horizontal">ПІДТРИМКИ</span>
      </span>
    </div>
    <div
      class="timeline-recording-line"
      on:click="{toRecording}"
      style="left: {recordingTextStartPosition}%; width: {recordingLength}%;"
    ></div> -->
  <!-- </div> -->
</div>
