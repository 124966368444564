<style lang="scss">.about-block {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto; }

.top {
  padding: 24px; }
  .top-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr); }

a {
  text-decoration: none; }

.about-block-subtitle {
  margin-top: 28px;
  grid-column-start: 2;
  grid-column-end: 6;
  text-align: left; }

.about-block-text {
  font-family: Montserrat;
  font-size: 0.875rem;
  line-height: 161.5%;
  grid-column-start: 2;
  grid-column-end: 6; }

.about-block-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 24px 24px 40px;
  background: #ebebeb;
  width: 100%;
  min-height: fit-content; }
  .about-block-buttons .text {
    font-family: Montserrat;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: rgba(26, 26, 26, 0.6);
    margin-bottom: 6px; }
    .about-block-buttons .text a {
      text-decoration: none;
      color: rgba(26, 26, 26, 0.6);
      text-transform: none; }
  @media (orientation: portrait) {
    .about-block-buttons {
      flex-direction: column;
      align-items: center;
      background: #ebebeb; } }

.about-block-button {
  display: grid;
  grid-template-columns: repeat(6, 1fr); }
  .about-block-button p {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 161.5%;
    margin-bottom: 15px; }

.about-block-button--first {
  margin-right: 24px; }

.number {
  grid-column: span 1;
  font-family: 'Transgender Grotesk';
  font-size: 2.125rem;
  line-height: 2.5625rem;
  color: transparent;
  -webkit-text-stroke: 1px black;
  width: 30px; }

.text {
  grid-column: span 5;
  align-self: center; }

.background-onahau {
  background: #c6dae3; }

.background-white {
  background: #fff; }

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }
  .video-container .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.padding-zero {
  padding: 0; }

.padding-m {
  padding: 24px; }

.sharing {
  display: flex;
  margin: auto auto 24px auto; }
  .sharing .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    padding: 4px;
    background: #94b6c5;
    border-radius: 50%;
    margin-left: 18px; }
    .sharing .icon:first-child {
      margin-left: 0; }
    .sharing .icon img {
      width: 100%;
      height: 100%; }
  .sharing .yellow {
    background: #feb903; }

.uppercase {
  text-transform: uppercase; }

.button {
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000; }

.wrapper {
  display: flex;
  align-items: center;
  min-height: 56px; }

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border: 1px solid #000;
  border-radius: 16px;
  height: 53px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: fit-content;
  min-width: 140px; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #000;
  font-family: 'Transgender Grotesk';
  height: 42px;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  text-align: center;
  letter-spacing: 0.15em;
  padding: 0 16px;
  width: 100%;
  white-space: nowrap; }
/*# sourceMappingURL=src/components/Final/About.svelte.map */</style>

<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { TweenMax } from 'gsap';
  import FinalBack from './FinalBack.svelte';
  import Button from '../Button.svelte';
  import Footer from '../Footer.svelte';
  import { getGtag } from '../../tracking';

  let video;

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }

  const dispatch = createEventDispatcher();

  let gtag;
  let wrapperRef;

  onMount(async () => {
    // TweenMax.set(wrapperRef, { translateX: '100%' });
    if (typeof YT !== 'undefined' && YT.loaded) {
      startPlayer();
    }
  });

  document.addEventListener('swiped-right', back);

  export async function enterAnimation() {
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
    gtag = await getGtag();
    gtag.page('/about');
  }

  export function exitAnimation() {
    TweenMax.to(wrapperRef, 0.4, { translateX: '100%' });
  }

  function back() {
    exitAnimation();

    // dispatch('back');
    history.back();
  }

  function startPlayer() {
    let playing;
    const player = new YT.Player('video', {
      height: '315',
      width: '100',
      videoId: 'nzbDlxA2oH8',
      events: {
        onStateChange: onPlayerStateChange
      }
    });

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        trackEventOnce('about_us', 'play_video');
        playing = true;
      } else if (event.data == YT.PlayerState.PAUSED) {
        playing = false;
      }
    }
  }
  function goToCollection() {
    trackEventOnce('about_us', 'to_waitlist');
    dispatch('goToCollection');
  }
  window.onYouTubeIframeAPIReady = () => startPlayer();
</script>

<section bind:this="{wrapperRef}" class="about-block">
  <script src="https://www.youtube.com/iframe_api">

  </script>
  <div class="top">
    <FinalBack title="Про проєкт" on:back="{back}" />
    <div class="top-content">
      <p class="about-block-text">
        «Різні.Рівні» — перші, хто об’єднав союзників та союзниць ЛГБТК+ спільноти в один потужний
        рух на підтримку рівності для всіх: попри гендерну ідентичність та сексуальну орієнтацію.
        Зараз 14% українців та українок вважають, що суспільство має прийняти ЛГБТК+. Ми хочемо
        збільшувати цю цифру щорічно.
      </p>
      <div class="about-block-subtitle">ДЛЯ ЦЬОГО МИ:</div>
    </div>
  </div>
  <div class="about-block-buttons">
    <div class="about-block-button">
      <div class="number">1</div>
      <div class="text">
        <p>
          Створили айдентику бренду, яку можуть використати всі охочі підтримати рівність в Україні.
        </p>
      </div>
    </div>
    <div class="wrapper">
      <div class="button-wrapper">
        <a
          href="https://logo.riznirivni.com.ua"
          target="_blank"
          on:click="{() => trackEvent('about_us', 'more_about_logo')}"
          class="button"
        >
          Дізнатися більше
        </a>
      </div>
    </div>
  </div>
  <div class="about-block-buttons background-onahau">
    <div class="about-block-button">
      <div class="number">2</div>
      <div class="text">
        <p>
          Разом із трьома всесвітньо відомими дизайнерками LITKOVSKAYA, BEVZA та KSENIASCHNAIDER
          запустили першу в світі Колекцію Рівності, що належить усім, хто підтримує рівні права.
          <br />
          Речі подорожують від людини до людини, аби всі, хто хоче показати підтримку, могли це
          зробити.
        </p>
      </div>

    </div>
    <div class="main-wrapper">
      <div class="button-wrapper">
        <div on:click="{goToCollection}" class="button">Дізнатися більше</div>
      </div>
    </div>
  </div>
  <div class="about-block-buttons background-white padding-zero">
    <div class="about-block-button padding-m">
      <div class="number">3</div>
      <div class="text">
        <p>
          Розробили першу в Україні музичну колаборацію, в межах якої 15 українських музикантів й
          музиканток відкрито заявили про підтримку рівних прав для всіх. Проєкт став колабою року
          на Jager Music Awards 2020.
        </p>
      </div>
    </div>
    <div class="video-container">
      <div id="video" class="video" bind:this="{video}"></div>
      <!-- <iframe
        class="video"
        width="100"
        height="315"
        src="https://www.youtube.com/embed/nzbDlxA2oH8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
        picture-in-picture"
        allowfullscreen
      ></iframe> -->
    </div>
    <div class="about-block-button padding-m">
      <div class="number"></div>
      <div class="text">
        <p>
          Дякуємо: Alina Pash, Сергій Бабкін, Constantine, LATEXFAUNA, YUKO, Гурт [О], KRUTЬ, Daniel
          Shake, Anton Prybytkin (Secret Avenue), Kulshenka, Ofliyan, Lucas Bird, Shy, U:LAV,
          WWWAAAVVVEEE, The Maneken.
        </p>
      </div>
    </div>
  </div>
  <div class="about-block-buttons">
    <div class="about-block-button">
      <div class="number">4</div>
      <div class="text">
        <p>
          Запустили в Instagram проєкт #різнірівні365: щодня представники й представниці ЛГБТК+
          спільноти розповідають у сторіз акаунту Різні.Рівні про своє життя. Щиро і без фільтрів. І
          доводять усьому світу, що всіми – Різні, але Рівні.
        </p>
      </div>
    </div>
    <div class="wrapper">
      <div class="button-wrapper">
        <a
          href="https://www.instagram.com/rizni.rivni/"
          target="_blank"
          on:click="{() => trackEvent('about_us', 'watch_instagram_rizni365')}"
          class="button"
        >
          Подивитися
        </a>
      </div>
    </div>
  </div>
  <div class="about-block-buttons background-onahau">
    <div class="about-block-button">
      <div class="number">5</div>
      <div class="text">
        <p>
          Створили інтерактивний онлайн Музей Прийняття, в якому з батьками ЛГБТК+ дітей можна
          пройти весь їх довгий шлях прийняття.
        </p>
      </div>
    </div>
    <div class="wrapper">
      <div class="button-wrapper">
        <a
          href="https://www.ridni.com.ua/"
          target="_blank"
          on:click="{() => trackEvent('about_us', 'to_ridni')}"
          class="button"
        >
          Перейти
        </a>
      </div>
    </div>

  </div>

  <Footer class="background-white" />
</section>
