<style lang="scss">* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed; }

.hidden {
  visibility: hidden; }

.step-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.waitlist {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column; }

.wrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%; }
  .wrapper .inner-content {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    position: relative; }
    .wrapper .inner-content .content {
      grid-column: span 10;
      width: 100%; }
      .wrapper .inner-content .content .top-composition {
        display: grid;
        grid-template-columns: repeat(2, 1fr); }
        .wrapper .inner-content .content .top-composition__content {
          padding-top: 60px;
          margin-left: 48px;
          display: flex;
          flex-direction: column;
          width: 100%; }
          .wrapper .inner-content .content .top-composition__content__title {
            font-family: 'Transgender Grotesk';
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 2.4375rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: rgba(26, 26, 26, 0.8); }
          .wrapper .inner-content .content .top-composition__content__subtitle {
            margin-top: 33px;
            font-family: 'Montserrat';
            font-size: 1.125rem;
            line-height: 135.4%;
            letter-spacing: 0.02em;
            color: #1a1a1a; }
          .wrapper .inner-content .content .top-composition__content__description {
            margin-top: 14px;
            font-family: 'Montserrat';
            font-size: 0.875rem;
            line-height: 135.4%;
            letter-spacing: 0.02em;
            color: #1a1a1a; }
        .wrapper .inner-content .content .top-composition .image-wrapper {
          position: relative; }
          .wrapper .inner-content .content .top-composition .image-wrapper__inner {
            min-width: 550px;
            width: 550px;
            height: 550px; }
          .wrapper .inner-content .content .top-composition .image-wrapper img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            object-position: top center;
            opacity: 0; }

.success-title {
  font-size: 0.875rem;
  line-height: 1.4375rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1a1a1a; }

.success-people {
  color: #000000;
  font-size: 0.75rem;
  letter-spacing: 0.04em;
  font-family: Montserrat;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 40px; }
  .success-people__title {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 10px; }
  .success-people__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px; }
    .success-people__list .item {
      display: flex;
      margin-left: 6px;
      font-size: 0.875rem;
      font-family: Montserrat;
      line-height: 1.25rem;
      letter-spacing: 0.04em;
      color: #000000;
      opacity: 0.8; }
    .success-people__list a {
      text-decoration: none;
      color: #000; }
    .success-people__list .icon-wrapper {
      width: 10px;
      height: 10px;
      margin-left: 3px; }
      .success-people__list .icon-wrapper img {
        width: 100%;
        height: 100%; }

.popup-block {
  margin-top: auto;
  max-width: 500px; }

.popup-title {
  font-size: 1.5rem;
  line-height: 140%;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: Transgender Grotesk;
  color: #1a1a1a;
  margin-bottom: 40px; }

.popup-text {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin-bottom: 27px;
  font-size: 1.125rem;
  line-height: 161.5%;
  letter-spacing: 0.05em;
  color: #1a1a1a;
  font-family: 'Montserrat'; }
  .popup-text:last-child {
    margin-bottom: 0; }
  .popup-text .number {
    grid-column: span 1;
    font-size: 3.25rem;
    line-height: 3.875rem;
    color: #fff;
    -webkit-text-stroke: 1px black;
    width: 65px;
    font-family: Transgender Grotesk; }
  .popup-text .text {
    grid-column: span 8;
    align-self: center; }

.sharing {
  margin: 29px 0;
  align-self: center;
  display: flex;
  align-items: baseline; }
  .sharing-text {
    align-self: center;
    margin-left: 23px;
    text-align: center;
    font-family: Montserrat;
    max-width: 200px;
    font-size: 0.625rem;
    line-height: 0.75rem;
    letter-spacing: 0.04em;
    color: #000000;
    opacity: 0.8; }
    .sharing-text__span {
      display: block;
      margin-bottom: 4px; }

.bold {
  font-weight: 500;
  font-family: 'Montserrat-Medium'; }

.sharing-wrapper {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  width: 100%; }

.winners {
  margin-bottom: 16px; }

.item-winner {
  position: relative;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.04em;
  margin-bottom: 6px;
  margin-left: 20px;
  font-family: Montserrat;
  color: #000; }
  .item-winner a {
    color: #000;
    text-decoration: none; }
  .item-winner:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    background-image: url("/images/instagram.svg");
    background-size: contain;
    width: 14px;
    height: 14px; }

.toggle {
  margin-top: 7px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: Montserrat;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #1a1a1a;
  opacity: 0.3;
  cursor: pointer; }

.users-list-block {
  margin-top: 47px; }

.footer-wrapper {
  margin-top: auto; }
/*# sourceMappingURL=src/components/WaitlistDesktop.svelte.map */</style>

<script>
  import { initSocket } from '../helpers/websocket';
  import { createEventDispatcher } from 'svelte';
  import { onMount, tick, onDestroy } from 'svelte';
  import { getGtag } from '../tracking';
  import gsap, { TweenMax } from 'gsap';
  import Footer from './Footer.svelte';
  import Form from './Form.svelte';
  import MobileSharing from './MobileSharing.svelte';
  import { FirebaseApp, Collection } from 'sveltefire';
  import firebase from '../helpers/firebase';

  let socket;
  let counter = '';
  let href = "https://www.riznirivni.com.ua" + `/map`;
  let wrapperRef;
  let toggleButton;
  const dispatch = createEventDispatcher();

  let data = [];
  let photos = [];
  let randomTen = [];
  let finalWinners = [];

  let expanded = false;
  let interval = null;

  let contacts = {
    items: [],
    total: 0
  };

  export async function enterAnimation() {
    gtag = await getGtag();
    // gtag.page('/museum');
    TweenMax.to(wrapperRef, 0.4, { opacity: '1' });
  }

  export async function connectToSocket() {
    socket = initSocket(onMessage);
    let gtag = await getGtag();
    gtag.page('/stub');
  }

  function onMessage(e) {
    if (e.type === 'counter') {
      counter = e.data;
    }
  }

  function toggleBlock() {
    expanded = !expanded;
    expanded ? (toggleButton.innerHTML = 'Звернути') : (toggleButton.innerHTML = 'Читати повністю');
  }

  function wordDeclension(value, word) {
    if (value % 10 > 1 && value % 10 < 5) {
      return word[0];
    }
    if (value % 10 == 1) {
      return word[1];
    } else {
      return word[2];
    }
  }

  function getTenRandomUsers() {
    fetch(`https://${"api.riznirivni.com.ua"}/random-10`)
      .then(response => response.json())
      .then(item => (randomTen = item))
      .catch(() => []);
  }

  onMount(async () => {
    await Promise.all([
      fetch(`https://${"api.riznirivni.com.ua"}/week_winners`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/photos`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/forms`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/random-10`)
        .then(response => response.json())
        .catch(() => []),
      fetch(`https://${"api.riznirivni.com.ua"}/final-winners`)
        .then(response => response.json())
        .catch(() => [])
    ]).then(([res1, res2, res3, res4, res5]) => {
      data = res1;
      photos = res2;
      contacts = res3;
      randomTen = res4;
      finalWinners = res5;
    });
    let images = document.querySelectorAll('[data-index]');
    images[0].style.opacity = 1;

    function fadeIn(el) {
      gsap.fromTo(el, { opacity: 0 }, { opacity: 1 });
    }
    function fadeOut(el) {
      gsap.fromTo(el, { opacity: 1 }, { opacity: 0 });
    }

    let currentImage = Object.values(images).map((item, index) => index);
    let active = 0;
    let previous = 0;

    function animate() {
      interval = setInterval(() => {
        previous = active;
        active = active === images.length - 1 ? 0 : active + 1;
        fadeOut(images[previous]);
        fadeIn(images[active]);
        interval = null;
      }, 4000);
    }

    animate();
  });

  onDestroy(() => {
    if (interval) {
      clearInterval(interval);
    }
  });
</script>

<div class="waitlist">
  <div class="wrapper" bind:this="{wrapperRef}">
    <div class="inner-content">
      <div class="content">
        <div class="top-composition">

          <div class="image-wrapper">
            <div class="image-wrapper__inner">
              {#if photos.length}
                {#each photos as photo, i}
                  <img src="{photo}" alt="" data-index="{i}" />
                {/each}
              {/if}
            </div>
          </div>

          <div class="top-composition__content">
            <h1 class="top-composition__content__title">Продовжуй хвилю підтримки</h1>
            <p class="top-composition__content__subtitle">
              Колекція Рівності в колаборації LITKOVSKAYA х BEVZA х KSENIASCHNAIDER подорожує від
              людини до людини. І всі, хто дружній до ЛГБТК+, може заявити про свою позицію.
            </p>
            <p class="top-composition__content__description">
              Залишаємо списки відкритими, щоб ви завжди знали, кому можна передати річ і поширити
              хвилю підтримки ❤️
            </p>
            <div class="sharing-wrapper">
              <div class="success-people__title">У фінальному дропі речі отримали:</div>

              <div class="success-people__list">
                {#each finalWinners as contact, i}
                  {#if i < 10 && expanded === false}
                    <a class="item" href="{contact.link}" target="_blank">
                      {contact.fullName}
                      <div class="icon-wrapper">
                        <svg
                          width="10px"
                          height="10px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 55.99 76.6"
                          style="enable-background:new 0 0 55.99 76.6;"
                          xml:space="preserve"
                          fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                        >
                          <path
                            class="st0"
                            d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                            c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                            c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                            c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                            c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                            C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  {/if}
                  {#if expanded === true}
                    <a class="item" href="{contact.link}" target="_blank">
                      {contact.fullName}
                      <div class="icon-wrapper">
                        <svg
                          width="10px"
                          height="10px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 55.99 76.6"
                          style="enable-background:new 0 0 55.99 76.6;"
                          xml:space="preserve"
                          fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                        >
                          <path
                            class="st0"
                            d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                            c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                            c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                            c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                            c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                            C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  {/if}
                {/each}
              </div>
              <div class="toggle" on:click="{toggleBlock}" bind:this="{toggleButton}">
                Читати повністю
              </div>
              <div class="users-list-block">
                <div class="success-people__title">Люди, яким можна передати речі далі:</div>
                <div class="success-people__list">
                  {#each randomTen as contact, i}
                    <a class="item" href="{contact.link}" target="_blank">
                      {contact.fullName}
                      <div class="icon-wrapper">
                        <svg
                          width="10px"
                          height="10px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 55.99 76.6"
                          style="enable-background:new 0 0 55.99 76.6;"
                          xml:space="preserve"
                          fill="{i % 3 === 0 ? '#94B6C5' : i % 3 === 1 ? '#FCAACE' : '#FEB903'}"
                        >
                          <path
                            class="st0"
                            d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
                            c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
                            c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
                            c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
                            c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
                            C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  {/each}
                </div>
                <div class="toggle" on:click="{getTenRandomUsers}">Оновити</div>
              </div>
            </div>
          </div>

        </div>
        <div class="sharing">
          <div class="el-wrapper">
            <MobileSharing />
          </div>
          <div class="sharing-text">
            <span class="sharing-text__span">Стань частиною Різні.Рівні</span>
            Рух друзів ЛГБТК+ на підтримку рівності для всіх
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-wrapper">
    <Footer />
  </div>
</div>
