<style lang="scss">.final-intro {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: #ebebeb; }
  @media (orientation: landscape) {
    .final-intro {
      flex-direction: column; } }
  .final-intro__title {
    font-family: Transgender Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.875rem;
    padding-right: 20px; }
  @media (orientation: portrait) {
    .final-intro {
      flex-direction: column; } }
  .final-intro__block {
    display: flex;
    flex-direction: column; }
    .final-intro__block .intro-title {
      font-size: 1.25rem;
      line-height: 149%; }
    .final-intro__block--first {
      padding: 28px 40px;
      height: 70%;
      background: #fff;
      flex-grow: 1; }
      @media (orientation: landscape) {
        .final-intro__block--first {
          flex-direction: column; } }
    .final-intro__block--last {
      padding: 20px 30px;
      justify-content: space-around;
      min-height: 20%; }
      @media (orientation: landscape) {
        .final-intro__block--last {
          flex-direction: row;
          justify-content: space-around;
          padding: 0;
          height: 60px; } }
    @media (orientation: portrait) {
      .final-intro__block {
        width: 100%; } }
  .final-intro .first-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 15%;
    height: 90%; }
    @media (orientation: landscape) {
      .final-intro .first-content {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 0;
        height: 100%; } }
  .final-intro__button {
    outline: none;
    position: relative;
    font-family: 'Transgender Grotesk';
    margin-left: 24px;
    text-align: left;
    font-size: 0.875rem;
    background: transparent;
    border: none;
    text-decoration: underline; }
    @media (orientation: landscape) {
      .final-intro__button {
        margin: 0;
        font-size: 0.75rem;
        line-height: 3.75rem; } }
  .final-intro .dont-support-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%; }
    @media (orientation: landscape) {
      .final-intro .dont-support-first {
        width: 50%;
        justify-content: space-around; } }
  .final-intro .back-wrapper,
  .final-intro .go-to-start {
    display: flex;
    align-items: center;
    margin-bottom: 30px; }
    .final-intro .back-wrapper .text,
    .final-intro .go-to-start .text {
      margin-left: 5px;
      font-size: 0.75rem;
      line-height: 0.8125rem;
      color: #737272; }
  .final-intro .go-to-start {
    margin-bottom: 0; }
  .final-intro .dont-support-last {
    min-height: 20%;
    justify-content: space-around;
    padding: 20 40px; }
    .final-intro .dont-support-last .final-intro__button {
      margin-bottom: 30px;
      margin-left: 0; }
      .final-intro .dont-support-last .final-intro__button:last-child {
        margin-bottom: 0; }
    @media (orientation: landscape) {
      .final-intro .dont-support-last {
        flex-direction: column;
        width: 50%;
        height: 100%; }
        .final-intro .dont-support-last .final-intro__button {
          margin-bottom: 20px;
          font-size: 0.875rem; }
          .final-intro .dont-support-last .final-intro__button:last-child {
            margin-bottom: 0; } }
  .final-intro .copy-success-text {
    margin: 12px 0 0 0;
    font-size: 0.75rem;
    color: #feb903; }
    @media (orientation: portrait) {
      .final-intro .copy-success-text {
        text-align: center; } }
  .final-intro .copyright,
  .final-intro .copyright__dont-support {
    padding: 20px 30px;
    font-size: 0.5rem;
    line-height: 150.5%;
    background: #fff; }
    @media (orientation: landscape) {
      .final-intro .copyright,
      .final-intro .copyright__dont-support {
        display: flex;
        align-items: center;
        padding: 10px 40px; } }

.dont-support-wrapper .copyright__dont-support {
  display: none;
  padding: 0; }

@media (orientation: landscape) {
  .dont-support-wrapper {
    flex-direction: row; }
    .dont-support-wrapper .copyright {
      display: none; }
    .dont-support-wrapper .copyright__dont-support {
      display: block; } }
/*# sourceMappingURL=src/components/Final/Intro.svelte.map */</style>

<script>
  import { TweenMax, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import ShareLinks from '../ShareLinks.svelte';
  import { respondH } from '../../helpers/vh';
  import { getGtag } from '../../tracking';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  const dispatch = createEventDispatcher();
  let supportLgbt;
  let wrapperRef;
  let shareLinksWrapper;
  let shareLinksRef;
  let introTitle;
  let buttonsRef;
  let gtag;
  let entered;

  export async function enterAnimation() {
    if (entered) {
      return;
    }
    entered = true;
    TweenMax.to(wrapperRef, 0.4, {
      translateX: '0%',
      zIndex: 999,
      onComplete: () => {
        entered = false;
      }
    });
    gtag = await getGtag();
    gtag.page(!supportLgbt ? '/dontsupport' : '/share');
  }

  export function exitAnimation() {
    TweenMax.to(wrapperRef, 0.4, { translateX: '-100%', zIndex: -1 });
  }

  function setFigureInitialPosition() {
    const introTitleParams = { fontSize: respondH({ xs: '12px', xl: '24px' }, '20px') };
    const buttonsRefParams = { fontSize: respondH({ xs: '12px', s: '12px' }, '14px') };
    const sharedLinksWrapperParams = {
      scale: respondH({ xs: 0.8, s: 0.8 }, 1),
      y: respondH({ xs: '20px', s: '20px' }, '20px')
    };
    TweenMax.set(introTitle, introTitleParams);
    TweenMax.set(buttonsRef, buttonsRefParams);
    TweenMax.set(shareLinksWrapper, sharedLinksWrapperParams);
  }

  function goBack() {
    dispatch('back');
    sessionStorage.removeItem('supportLgbt');
  }

  function goToStart() {
    dispatch('goToStart');
  }

  export async function assignSupportLgbt() {
    supportLgbt = sessionStorage.getItem('supportLgbt') !== 'false';
    await tick();
    if (supportLgbt) {
      shareLinksRef.assignSupportLgbt();
    }
  }

  onMount(async () => {
    setFigureInitialPosition();
  });
</script>

<section class="final-intro {!supportLgbt ? 'dont-support-wrapper' : ''}" bind:this="{wrapperRef}">
  <div
    class="final-intro__block final-intro__block--first {!supportLgbt ? 'dont-support-first' : ''}"
  >
    {#if supportLgbt}
      <div class="go-to-start" on:click="{goToStart}">
        <svg
          class="arrow"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.72059 1.22059L8.5 0.999999L5.24095 4.27036C2.90473 6.61469 2.90801 10.408 5.24829
            12.7483L8.5 16L8.94118 15.5588"
            stroke="#797979"
          ></path>
          <path
            d="M9.82351 14.6765L10.1771 15.0301L10.5306 14.6765L10.1771 14.323L9.82351
            14.6765ZM3.64704 8.50005L3.29349 8.14649L2.93994 8.50005L3.29349 8.8536L3.64704
            8.50005ZM9.82352 2.32358L10.1771 2.67713L10.5306 2.32358L10.1771 1.97002L9.82352
            2.32358ZM10.1771 14.323L4.0006 8.14649L3.29349 8.8536L9.46996 15.0301L10.1771
            14.323ZM4.0006 8.8536L10.1771 2.67713L9.46996 1.97002L3.29349 8.14649L4.0006
            8.8536ZM10.1771 1.97002L9.07413 0.867081L8.36702 1.57419L9.46996 2.67713L10.1771
            1.97002ZM9.46996 14.323L8.58761 15.2053L9.29472 15.9124L10.1771 15.0301L9.46996 14.323Z"
            fill="#797979"
          ></path>
        </svg>
        <span class="text">повернутися на початок</span>
      </div>
      <div class="first-content">
        <p class="intro-title" bind:this="{introTitle}">
          Допоможи толерантності набрати обертів! Поділися нашим проєктом з друзями
        </p>
        <div class="share-links-wrappe" bind:this="{shareLinksWrapper}">
          <ShareLinks bind:this="{shareLinksRef}" />
        </div>
      </div>
    {:else}
      <div class="back-wrapper" on:click="{goBack}">
        <svg
          class="arrow"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.72059 1.22059L8.5 0.999999L5.24095 4.27036C2.90473 6.61469 2.90801 10.408 5.24829
            12.7483L8.5 16L8.94118 15.5588"
            stroke="#797979"
          ></path>
          <path
            d="M9.82351 14.6765L10.1771 15.0301L10.5306 14.6765L10.1771 14.323L9.82351
            14.6765ZM3.64704 8.50005L3.29349 8.14649L2.93994 8.50005L3.29349 8.8536L3.64704
            8.50005ZM9.82352 2.32358L10.1771 2.67713L10.5306 2.32358L10.1771 1.97002L9.82352
            2.32358ZM10.1771 14.323L4.0006 8.14649L3.29349 8.8536L9.46996 15.0301L10.1771
            14.323ZM4.0006 8.8536L10.1771 2.67713L9.46996 1.97002L3.29349 8.14649L4.0006
            8.8536ZM10.1771 1.97002L9.07413 0.867081L8.36702 1.57419L9.46996 2.67713L10.1771
            1.97002ZM9.46996 14.323L8.58761 15.2053L9.29472 15.9124L10.1771 15.0301L9.46996 14.323Z"
            fill="#797979"
          ></path>
        </svg>
        <span class="text">повернутися</span>
      </div>
      <h2 class="final-intro__title">Дізнайся, про які права ЛГБТК+ йдеться мова</h2>
      <footer class="copyright__dont-support">
        © Різні.Рівні, 2020. Ідея та Розробка проэкту:
        <a href="https://isd-group.com/" target="_blank">[isdgroup]</a>
        .
      </footer>
    {/if}
  </div>

  <div
    bind:this="{buttonsRef}"
    class="final-intro__block final-intro__block--last {!supportLgbt ? 'dont-support-last' : ''}"
  >
    {#if !supportLgbt}
      <button class="final-intro__button" on:click="{() => dispatch('next', 'rights')}">
        Права ЛГБТК+
      </button>

      <button class="final-intro__button" on:click="{() => dispatch('next', 'myths')}">
        Міфи про ЛГБТ
      </button>
    {/if}
    <button class="final-intro__button" on:click="{() => dispatch('next', 'about')}">
      Про проєкт
    </button>

    {#if supportLgbt}
      <button class="final-intro__button" on:click="{() => dispatch('next', 'partners')}">
        Наші партнери
      </button>
    {/if}

    <!-- <button class="final-intro__button" on:click="{() => dispatch('next', 'museum')}">
      Музей прийняття
    </button> -->
  </div>

  <footer class="copyright">
    © Різні.Рівні, 2020. Ідея та Розробка проэкту:
    <a href="https://isd-group.com/" target="_blank">[isdgroup]</a>
    .
  </footer>
</section>
