<style lang="scss">.back-button {
  font-family: 'Transgender Grotesk';
  background: transparent;
  border: none;
  outline: none;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  font-size: calc(2vh + 0.5vw);
  line-height: 140%;
  letter-spacing: 0.05em;
  text-transform: uppercase; }
  .back-button__text {
    margin-left: 4px; }
/*# sourceMappingURL=src/components/Final/FinalBack.svelte.map */</style>

<script>
  import { createEventDispatcher } from 'svelte';
  export let title;

  const dispatch = createEventDispatcher();
</script>

<button class="back-button" on:click="{() => dispatch('back')}">
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3824 1.38235L14 1L5.25047 9.77988C2.91425 12.1242 2.91753 15.9175 5.25781 18.2578L14
      27L14.7647 26.2353"
      stroke="black"
    ></path>
    <path
      d="M16.2941 24.7059L16.6476 25.0594L17.0012 24.7059L16.6476 24.3523L16.2941 24.7059ZM5.58818
      14L5.23462 13.6464L4.88107 14L5.23462 14.3536L5.58818 14ZM16.2941 3.29412L16.6476
      3.64767L17.0012 3.29412L16.6476 2.94056L16.2941 3.29412ZM16.6476 24.3523L5.94173
      13.6464L5.23462 14.3536L15.9405 25.0594L16.6476 24.3523ZM5.94173 14.3536L16.6476
      3.64767L15.9405 2.94056L5.23462 13.6464L5.94173 14.3536ZM16.6476 2.94056L14.7358
      1.0288L14.0287 1.7359L15.9405 3.64767L16.6476 2.94056ZM15.9405 24.3523L14.4111 25.8817L15.1182
      26.5888L16.6476 25.0594L15.9405 24.3523Z"
      fill="black"
    ></path>
  </svg>
  <span class="back-button__text">{title}</span>
</button>
