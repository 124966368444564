<style lang="scss">* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed; }

.hidden {
  visibility: hidden; }

.step-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.about {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .about-wrapper {
    max-width: 1050px;
    margin: 0 auto;
    padding: 60px 20px 0;
    width: 100%; }
    .about-wrapper .inner-content {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      width: 100%;
      position: relative;
      margin-bottom: 30px; }
  .about-title {
    grid-column: span 10;
    font-size: 1.5rem;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    max-width: 600px; }
  .about-description {
    grid-column: span 10;
    margin-top: 36px;
    font-size: 1.25rem;
    line-height: 161.5%;
    color: #000000;
    margin-left: 57px;
    font-family: Montserrat;
    max-width: 900px; }
  .about-content {
    grid-column: span 10;
    margin-top: 100px;
    margin-left: 57px; }
    .about-content__item {
      margin-bottom: 90px;
      position: relative;
      display: flex;
      width: 450px;
      margin-left: auto;
      margin-right: 57px; }
      .about-content__item:nth-child(2n) {
        margin-left: 0;
        margin-right: 0; }
      .about-content__item .number {
        grid-column: span 1;
        font-family: 'Transgender Grotesk';
        font-size: 3.25rem;
        line-height: 3.875rem;
        color: transparent;
        -webkit-text-stroke: 1px black;
        width: 30px; }
      .about-content__item .content {
        grid-column: span 5;
        margin-left: 30px;
        font-family: Montserrat;
        font-size: 1.125rem;
        line-height: 161.5%;
        color: #000000; }

.main-wrapper {
  display: flex;
  align-items: center;
  min-height: 56px;
  margin-top: 40px; }

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  border: 1px solid #000;
  border-radius: 16px;
  height: 53px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 240px; }

.button {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #000;
  font-family: "Transgender Grotesk";
  height: 42px;
  letter-spacing: 0.3rem;
  background: transparent;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  padding: 18px 15px;
  text-transform: uppercase;
  white-space: nowrap; }

a {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  padding: 18px 15px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  letter-spacing: 0.15em; }

.content-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
  .content-wrapper .text {
    position: relative;
    margin-top: 20px;
    font-size: 0.75rem;
    line-height: 161.5%;
    color: #000000;
    margin-left: 70px;
    width: 230px; }
    .content-wrapper .text::before {
      content: '';
      position: absolute;
      top: 0;
      left: -68px;
      transform: rotate(-90deg);
      background-image: url("/images/big-arrow.svg");
      background-repeat: no-repeat;
      width: 42px;
      height: 46px; }

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 55.25%; }
  .video-container .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
/*# sourceMappingURL=src/components/AboutDesktop.svelte.map */</style>

<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { getGtag } from '../tracking';
  import ArrowBack from './ArrowBack.svelte';
  import Footer from './Footer.svelte';

  let video;

  const dispatch = createEventDispatcher();
  dispatch('disable-scroll');

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }
  async function trackEvent(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEvent(category, action);
  }
  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/about-desktop');
    document.body.className = 'scroll';
  }

  onMount(async () => {
    if (typeof YT !== 'undefined' && YT.loaded) {
      startPlayer();
    }
  });

  function startPlayer() {
    let playing;
    const player = new YT.Player('video', {
      height: '375',
      width: '213',
      videoId: 'nzbDlxA2oH8',
      events: {
        onStateChange: onPlayerStateChange
      }
    });

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        trackEventOnce('about_us', 'play_video');
        playing = true;
      } else if (event.data == YT.PlayerState.PAUSED) {
        playing = false;
      }
    }
  }
  function goToForm() {
    trackEventOnce('about_us', 'get_collection');

    dispatch('goToForm');
  }
  function goToCollection() {
    trackEventOnce('about_us', 'to_waitlist');
    dispatch('goToCollection');
  }
  function back() {
    dispatch('back');
  }
  window.onYouTubeIframeAPIReady = () => startPlayer();
</script>

<div class="about">
  <script src="https://www.youtube.com/iframe_api">

  </script>
  <div class="about-wrapper">
    <div class="wrapper">
      <div class="inner-content">
        <div class="about-title">
          <ArrowBack on:back="{back}" title="Про проєкт" />
        </div>
        <div class="about-description">
          «Різні.Рівні» — перші, хто об’єднав союзників та союзниць ЛГБТК+ спільноти в один потужний
          рух на підтримку рівності для всіх: попри гендерну ідентичність та сексуальну орієнтацію.
          <br />
          Зараз 14% українців та українок вважають, що суспільство має прийняти ЛГБТК+. Ми хочемо
          збільшувати цю цифру щорічно.
          <br />
          Для цього ми:
        </div>
        <div class="about-content">
          <div class="about-content__item">
            <div class="number">1</div>
            <div class="content">
              Створили айдентику бренду, яку можуть використати всі охочі підтримати рівність в
              Україні.
              <div class="main-wrapper">
                <div class="button-wrapper">
                  <a
                    href="https://logo.riznirivni.com.ua"
                    target="_blank"
                    on:click="{() => trackEvent('about_us', 'more_about_logo')}"
                    class="button"
                  >
                    Дізнатися більше
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="about-content__item">
            <div class="number">2</div>
            <div class="content">
              Разом із трьома всесвітньо відомими дизайнерками LITKOVSKAYA, BEVZA та KSENIASCHNAIDER
              запустили першу в світі Колекцію Рівності, що належить усім, хто підтримує рівні
              права.
              <br />
              Речі подорожують від людини до людини, аби всі, хто хоче показати підтримку, могли це
              зробити.
              <div class="main-wrapper">
                <div class="button-wrapper">
                  <div on:click="{goToCollection}" class="button">Дізнатися більше</div>
                </div>
              </div>
            </div>
          </div>
          <div class="about-content__item">
            <div class="number">3</div>
            <div class="content">
              Розробили першу в Україні музичну колаборацію, в межах якої 15 українських музикантів
              й музиканток відкрито заявили про підтримку рівних прав для всіх. Проєкт став колабою
              року на Jager Music Awards 2020.
              <div class="content-wrapper">
                <div class="video-container">
                  <div id="video" class="video" bind:this="{video}"></div>
                </div>
                <div class="text">
                  ДякуЄмо: Alina Pash, Сергій Бабкін, Constantine, LATEXFAUNA, YUKO, Гурт [О],
                  KRUTЬ, Daniel Shake, Anton Prybytkin (Secret Avenue), Kulshenka, Ofliyan, Lucas
                  Bird, Shy, U:LAV, WWWAAAVVVEEE, The Maneken.
                </div>
              </div>

            </div>
          </div>
          <div class="about-content__item">
            <div class="number">4</div>
            <div class="content">
              Запустили в Instagram проєкт #різнірівні365: щодня представники й представниці ЛГБТК+
              спільноти розповідають у сторіз акаунту Різні.Рівні про своє життя. Щиро і без
              фільтрів. І доводять усьому світу, що всі ми – Різні, але Рівні.
              <div class="main-wrapper">
                <div class="button-wrapper">
                  <a
                    href="https://www.instagram.com/rizni.rivni/"
                    target="_blank"
                    on:click="{() => trackEvent('about_us', 'watch_instagram_rizni365')}"
                    class="button"
                  >
                    Подивитися
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="about-content__item">
            <div class="number">5</div>
            <div class="content">
              Створили інтерактивний онлайн Музей Прийняття, в якому можна пережити досвід батьків
              ЛГБТК+ дітей
              <div class="main-wrapper">
                <div class="button-wrapper">
                  <a
                    href="https://www.ridni.com.ua/"
                    target="_blank"
                    on:click="{() => trackEvent('about_us', 'to_ridni')}"
                    class="button"
                  >
                    Перейти
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <Footer />
</div>
