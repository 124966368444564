<style lang="scss">* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed; }

.hidden {
  visibility: hidden; }

.step-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.wrapper {
  display: flex;
  align-items: center;
  min-height: 56px; }

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 4px;
  border: 1px solid #000;
  border-radius: 16px;
  height: 53px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; }
  .button-wrapper.active {
    border-color: #fd4802; }

.button {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #000;
  font-family: "Transgender Grotesk";
  height: 42px;
  letter-spacing: 0.3rem;
  background: transparent;
  outline: none;
  cursor: pointer; }
  .active .button {
    border-color: #fd4802; }

.text {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  letter-spacing: 0.15em;
  padding: 0 16px; }
  .text.animated {
    font-size: 1.375rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
/*# sourceMappingURL=src/components/Button.svelte.map */</style>

<script>
  import { TweenMax, Linear, TimelineLite } from 'gsap';
  import { onMount, createEventDispatcher } from 'svelte';

  export let text;
  export let activeText;
  export let width = 150;
  export let active;

  let isActive = false;
  let textElement;
  let activeTextElement;
  let buttonElement;
  let buttonWrapper;
  const dispatch = createEventDispatcher();

  export function animation(type) {
    return new Promise(onComplete => {
      const timeline = new TimelineLite({
        onComplete
      });

      timeline
        .to(type === 'end' ? activeTextElement : textElement, 0.4, {
          opacity: 0,
          scale: 1,
          ease: Linear.easeNone
        })
        .to(type === 'start' ? activeTextElement : textElement, 0.4, {
          opacity: 1,
          scale: 0.5,
          ease: Linear.easeNone
        })
        .to(
          buttonWrapper,
          0.4,
          { height: 52, width: 152, borderRadius: 10, ease: Linear.easeNone },
          0
        )
        .to(
          buttonElement,
          0.4,
          { height: 54, width: 156, borderRadius: 10, borderWidth: 0, ease: Linear.easeNone },
          0
        )
        .to(
          buttonWrapper,
          0.4,
          {
            height: 52,
            width: width + 14,
            borderRadius: 16,
            borderColor: type === 'end' ? 'black' : '#FD4802',
            ease: Linear.easeNone
          },
          0.4
        )
        .to(
          buttonElement,
          0.4,
          {
            height: 42,
            width,
            borderRadius: 0,
            borderWidth: 1,
            borderColor: type === 'end' ? 'black' : '#FD4802',
            ease: Linear.easeNone
          },
          0.4
        );
      isActive = !isActive;
    });
  }

  function onButtonClick() {
    dispatch('click');
  }

  onMount(() => {
    if (!activeText) {
      return;
    }
    TweenMax.set(activeTextElement, {
      opacity: 0,
      scale: 1
    });
    TweenMax.set(textElement, {
      scale: 0.5
    });
    TweenMax.set(buttonWrapper, {
      transformOrigin: 'center center',
      width: width + 14
    });
    TweenMax.set(buttonElement, {
      transformOrigin: 'center center',
      width
    });
  });
</script>

<div class="wrapper">
  <div
    class="button-wrapper {active ? 'active' : ''}"
    style="{`min-width:${width}px`}"
    bind:this="{buttonWrapper}"
  >
    <button class="button {$$props.class}" bind:this="{buttonElement}" on:click="{onButtonClick}">
      <span
        style="{`min-width:${width - 10}px`}"
        class:animated="{!!activeText}"
        bind:this="{textElement}"
        class="text"
      >
        {text}
      </span>
      {#if activeText}
        <span
          style="{`min-width:${width - 10}px`}"
          bind:this="{activeTextElement}"
          class="text animated"
        >
          {activeText}
        </span>
      {/if}
    </button>
  </div>
</div>
