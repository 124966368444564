<style lang="scss">svg text {
  font-size: 2.25rem;
  line-height: 2.6875rem; }

.big-hand {
  transform: scale(2.3) translate(0, -12%);
  color: white; }

.text-wrapper {
  position: absolute;
  top: 10%; }
  .text-wrapper span {
    display: block; }
  .text-wrapper .text {
    font-size: 0.875rem;
    font-family: 'Transgender Grotesk';
    line-height: 1.4375rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1a1a1a;
    width: 260px;
    padding: 0 20px; }

.step {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #ebebeb; }
  .step .buttons-wrapper {
    position: absolute;
    height: 100%;
    width: 100%; }
    .step .buttons-wrapper .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      height: 50px; }
      .step .buttons-wrapper .inner-wrapper .button {
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        font-family: Transgender Grotesk;
        font-size: 0.625rem;
        line-height: 0.8125rem;
        text-align: center;
        color: #737272; }
/*# sourceMappingURL=src/components/SecondWave.svelte.map */</style>

<script>
  import gsap, { TweenMax, Back, TimelineLite } from 'gsap';
  import { createEventDispatcher, onMount } from 'svelte';
  import { respondH } from '../helpers/vh';
  import Button from './Button.svelte';
  import { getGtag } from '../tracking';
  import Menu from './HeaderMenu.svelte';

  async function trackEventOnce(category, action) {
    if (typeof gtag === 'undefined') {
      gtag = await getGtag();
    }

    gtag.trackEventOnce(category, action);
  }

  const dispatch = createEventDispatcher();

  let gtag;
  let stepText;
  let figureContainer;
  let mask;
  let figure;
  let timeline;
  let infinityTimeline;
  let buttons;
  let wrapperRef;

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/museum');
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
  }

  onMount(async () => {
    TweenMax.set(wrapperRef, { translateX: '100%' });
    const supportLgbtStorage = sessionStorage.getItem('supportLgbt');
    if (supportLgbtStorage) {
      sessionStorage.removeItem('supportLgbt');
    }
  });

  async function support() {
    dispatch('support');

    window.location.href = '/demo-map';

    if (typeof DeviceMotionEvent === 'undefined') {
      trackEventOnce('intro', 'orientation_notsupported');
      return dispatch('denied');
    }

    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      await DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            dispatch('granted');
          } else {
            dispatch('denied');
          }
        })
        .catch(console.error);
    } else {
      dispatch('granted');
    }
  }

  function dontSupport() {
    dispatch('declined');
  }
</script>

<div class="step step-svg" bind:this="{wrapperRef}">
  <Menu />
  <div class="image-wrapper big-hand">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55.99 76.6"
      style="enable-background:new 0 0 55.99 76.6;"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M24.23,8.5c0.28,1.9,5.32,8.99-2.33,12.66c-0.05-0.96-0.78-18.45-0.91-20.67c-0.49,0.07-9.53,1.05-5.56,12.3
        c2.32,6.56-1.3,8.52-3.96,9.14c-0.44-7.32,0.05-14.02-0.38-21.22C9,0.78,6.89,2,5.71,3.74C3.5,6.98,3.72,9.83,5.26,13.3
        c1.26,2.83,1.71,7.85,0.18,10.49C-6.22,43.94,4.3,59.06,5.44,60.37c5.89,6.8,14.38,12.81,29.43,15.54
        c4.03,0.73,20.16,0.92,21.04,0.39c0.34-7.83-0.42-47.62-0.42-54.67c-1.68-0.14-3.35-0.23-5.62,0.87c-2.21,1.07-4.51,3.78-4.23,7.64
        c0.23,3.25,5.1,12.78-4.95,13.2L40,0.39c-2.4,0.3-7.9,1.6-5.75,9.22c1.05,3.72,2.21,5.92,0.57,8.89c-0.77,1.41-2.74,2.21-3.08,2.34
        C31.67,19.91,30.67,1.75,30.57,0C26.95,0.23,23.16,3.23,24.23,8.5"
        fill="currentColor"
      ></path>
    </svg>
  </div>
  <div class="text-wrapper">
    <div class="text">
      <span>
        Перша в світі колекцiя рiвностi від Litkovskaya, KseniaSchnaider
        <br />
        і BEVZA.
      </span>
      <span>
        її не можна придбати. лише отримати, якщо підтримуєш рівні права для лгбтк+ спільноти.
      </span>
    </div>
  </div>
  <div class="buttons-wrapper" bind:this="{buttons}">
    <div class="inner-wrapper">
      <Button text="підтримую" on:click="{support}" />
      <button class="button" on:click="{dontSupport}">не підтримую</button>
    </div>
  </div>
</div>
