<style lang="scss">.museum {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ebebeb;
  padding: 43px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box; }

.content {
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column; }

.wrapper {
  display: flex;
  align-items: center;
  min-height: 56px;
  margin: 28px auto 0; }

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  border: 1px solid #000;
  border-radius: 16px;
  height: 52px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: fit-content;
  min-width: 140px; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #000;
  font-family: 'Transgender Grotesk';
  height: 42px;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  text-align: center;
  letter-spacing: 0.15em;
  padding: 0 16px;
  width: 100%; }
  @media (min-width: 600px) {
    .button {
      width: 50vw;
      font-size: 1.25rem; } }

a {
  text-decoration: none;
  color: #000;
  text-transform: uppercase; }

.text {
  margin-top: 28px;
  margin-left: 30px;
  font-size: calc(2vh + 1vw);
  line-height: 161.5%;
  font-family: Montserrat;
  color: #000000; }

.footer {
  margin-top: auto; }
/*# sourceMappingURL=src/components/Final/Museum.svelte.map */</style>

<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { TweenMax } from 'gsap';
  import FinalBack from './FinalBack.svelte';
  import Button from '../Button.svelte';
  import Footer from '../Footer.svelte';
  import { getGtag } from '../../tracking';

  const dispatch = createEventDispatcher();

  let wrapperRef;
  let gtag;

  onMount(async () => {
    TweenMax.set(wrapperRef, { translateX: '100%' });
  });

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/dontsupport/museum');
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
  }

  export function exitAnimation() {
    TweenMax.to(wrapperRef, 0.4, { translateX: '100%' });
  }

  function back() {
    exitAnimation();
    // dispatch('back');
    history.back();
  }
</script>

<section bind:this="{wrapperRef}" class="museum">
  <div class="content">
    <FinalBack title="Музей прийняття" on:back="{back}" />
    <div class="text">
      Це проєкт, що розповідає реальні історії батьків, які подолали бар‘єри стереотипів на шляху до
      прийняття власних ЛГБТК+ дітей.
    </div>
    <div class="wrapper">
      <div class="button-wrapper">
        <a href="https://www.ridni.com.ua/" target="_blank" class="button">Перейти</a>
      </div>
    </div>
  </div>
  <div class="footer">
    <Footer />
  </div>
</section>
