<style lang="scss">.rights-texts {
  position: relative; }

.rights-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ebebeb;
  padding: 43px 0 0;
  overflow-y: scroll;
  overflow-x: hidden; }

.rights-title {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  margin: 0 0 20px;
  padding: 0 32px;
  color: #000000; }

.rights-text {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4375rem;
  padding-top: 6px;
  padding-right: 16px;
  color: #000000; }
  .rights-text--nested {
    padding-top: 0;
    white-space: pre-line; }
  .rights-text__block {
    display: flex;
    margin-left: 30px; }
    .rights-text__block .description {
      padding: 24px 0; }
  .rights-text__number {
    min-width: 18px;
    display: inline-block;
    margin-right: 14px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.875rem; }
    .rights-text__number--nested {
      font-size: 1.125rem; }

.bold {
  font-weight: bold; }

.background {
  width: auto;
  height: 100%;
  background: white;
  position: absolute;
  left: -2rem;
  right: -2rem;
  z-index: -1; }

.icon-wrapper {
  position: absolute;
  top: 27px;
  left: 5px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  color: #94b6c5; }
  .icon-wrapper img {
    width: 100%;
    height: 100%;
    color: #94b6c5; }

.block-wrapper {
  padding: 0 20px; }
/*# sourceMappingURL=src/components/Final/Rights.svelte.map */</style>

<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { TweenMax } from 'gsap';
  import FinalBack from './FinalBack.svelte';
  import Button from '../Button.svelte';
  import Footer from '../Footer.svelte';
  import { getGtag } from '../../tracking';

  const dispatch = createEventDispatcher();

  let wrapperRef;
  let gtag;

  const blocks = [
    {
      number: 1,
      title:
        'Гомосекуальні і трансгендерні люди рідко отримують ефективний захист чи відшкодування за злочини на ґрунті ненависті',
      text:
        'Цей вид злочинів рідко кваліфікується поліцією, оскільки стаття 161 Кримінального Кодексу України «Порушення рівноправності громадян залежно від їх расової, національної належності, релігійних переконань, інвалідності та за іншими ознаками», хоча і має відкритий перелік ознак, але серед наведених немає ні сексуальної орієнтації, ні гендерної ідентичності. Разом із тим, в Україні не запроваджено ефективного механізму розслідування таких випадків і визначення мотиву ненависті.'
    },
    {
      number: 2,
      title: 'ЛГБТК+ люди часто зазнають дискримінації',
      background: true,
      text:
        'Ознаки «сексуальна орієнтація» та «гендерна ідентичність» (далі СОГІ) не зазначені в жодних нормативно-правових актах, окрім ст. 2-1 Кодексу законів про працю України. Закон «Про засади запобігання і протидії дискримінації» має відкритий перелік ознак, але СОГІ в них не вказані. На практиці, довести факт дискримінації в суді досить складно, тим більше за ознаками СОГІ.'
    },
    {
      number: 3,
      title: 'Гомосексуальні люди в Україні не можуть бути донорами крові',
      text:
        'Згідно з Наказом МОЗ № 385 від 01.08.2005 р. Порядок медичного обстеження донорів крові та її компонентів обмежує у правах гомосексуальних людей здавати кров через стереотипи про ВІЛ/СНІД. Хоча згідно даних Фонду Олени Пінчук «Антиснід», лише 3% серед інфікованих заразилися через незахищений гомосексуальний статевий контакт. Але хвороба не знає орієнтації, та на неї можуть захворіти усі, хто не дотримується методів контрацепції.'
    },
    {
      number: 4,
      title: 'Неможливість реєстрації шлюбу чи партнерства',
      background: true,
      text:
        'Шлюб — це не тільки «штамп в паспорті», а ціла низка громадянських прав, яких набуває подружжя. Нині спроби затвердити форму партнерства для ЛГБТК+ пар всіляко блокуються релігійними та консервативними групами, — вони протидіють демократичному розвитку України. Через це права ЛГБТК+ людей обмежуються у таких сферах:'
    },
    {
      number: 4.1,
      nested: true,
      background: true,
      text: `Життя

            • ЛГБТК+ пари не можуть приймати рішення за партнера у критичних для життя і здоров’я ситуаціях, а також не мають права на утримання і догляд непрацездатного партнера (ст. 75 Сімейного кодексу України);
            
            • разом із тим неможливо отримувати «лікарняні дні» для догляду за хворим партнером (Наказ МОЗ No 455 від 13.11.2001, Розділ 3);
            
            • ЛГБТК+ люди не можуть бути піклувальником для недієздатного партнера (ст. 63 Цивільного кодексу України), як і оформити спільне медичне страхування.
      `
    },
    {
      number: 4.2,
      nested: true,
      background: true,
      text: `Майно

            • партнери у ЛГБТК+ парах не можуть спільно володіти майном (Глава 8 Сімейного кодексу України);

            • ЛГБТК+ пари не можуть успадковувати майно за законом (Глава 86, ст. 1261 Цивільного кодексу України) і отримати пов’язані з цим податкові пільги (спадкування за заповітом — 5% податку; за законом — нульова ставка, ст. 14 Податкового Кодексу України);
            
            • фактично, вони позбавляються пільгового кредитування (Положення Кабінету Міністрів України No 584 від 29.05.2001 «Про порядок надання пільгових довготермінових кредитів...»).
            `
    },
    {
      number: 4.3,
      nested: true,
      background: true,
      text: `Партнери не мають можливості спільно опікуватися чи піклуватися про дітей (ст. 211 Сімейного кодексу України)`
    },
    {
      number: 4.4,
      nested: true,
      background: true,
      text: `У суді ЛГБТК+ партнери не можуть відмовитися свідчити одне проти одного, оскільки не є зареєстрованими партнерами (ст. 63 Конституції України), а також обмежені в тривалості побачень у місцях позбавлення волі (ст. 110 Кримінально-виконавчого кодексу)`
    },
    {
      number: 4.5,
      nested: true,
      background: true,
      text:
        'Смерть одного з ЛГБТК+ партнерів фактично означає складнощі при отриманні тіла для поховання (особливо, якщо є конфлікт з родичами померлого) та неможливість бути похованим разом з партнером у родинній могилі (п. 4.1.6. Інструкції про порядок поховання..., Наказ Держжитлокомунгоспу No 126 від 16.12.1991)'
    },
    {
      number: 4.6,
      nested: true,
      background: true,
      text:
        'Відсутнє право на імміграцію партнера-іноземця в Україну (возз’єднання родини) (Наказ МВС No 681 від 15.07.2013 «Про затвердження тимчасового порядку розгляду заяв для оформлення посвідки на постійне проживання...»)'
    }
  ];

  onMount(async () => {
    TweenMax.set(wrapperRef, { translateX: '100%' });
  });

  export async function enterAnimation() {
    gtag = await getGtag();
    gtag.page('/dontsupport/rights');
    document.body.className = 'scroll';
    TweenMax.to(wrapperRef, 0.4, { translateX: '0%' });
  }

  export function exitAnimation() {
    document.body.className = '';
    TweenMax.to(wrapperRef, 0.4, { translateX: '100%' });
  }

  function back() {
    exitAnimation();
    // dispatch('back');
    history.back();
  }
</script>

<section bind:this="{wrapperRef}" class="rights-block">
  <div class="block-wrapper">
    <FinalBack title="Права ЛГБТК+" on:back="{back}" />
    {#each blocks as blockItem}
      <div class="rights-texts">
        {#if blockItem.background}
          <div class="background"></div>
        {/if}
        <div class="rights-text__block">
          <!-- <span class="rights-text__number {blockItem.nested ? 'rights-text__number--nested' : ''}">
          {blockItem.number}
        </span> -->
          {#if blockItem.nested}
            <div class="icon-wrapper">
              <img src="/images/hand.svg" alt="hand" />
            </div>
          {/if}
          <div class="description">
            {#if blockItem.title}
              <h5 class="rights-text bold">{blockItem.title}</h5>
            {/if}
            <p class="rights-text {blockItem.nested ? 'rights-text--nested' : ''}">
              {blockItem.text}
            </p>
          </div>
        </div>
      </div>
    {/each}
  </div>
  <Footer />
</section>
